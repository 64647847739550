.floor{
	text-align:center;
	color:#0e394b;
	.title{
		font-size:30px;
		font-weight:400;
		margin:30px 0 0px;
	}
	.subtitle{
		font-size:24px;
		font-weight:100;
	}
	.legend{
		display:flex;
		flex-direction:row;
		flex-wrap:wrap;
		justify-content:center;
		margin:20px 0 30px;
		.legend-item{
			position:relative;
			font-size:14px;
			font-weight:600;
			margin:0 30px;
			padding-left:33px;
			&:before{
				content:'';
				position:absolute;
				width:23px;
				height:23px;
				left:0;
				top:0;
			}
			&.free:before{
				background-color:#c3df81;
			}
			&.rent:before{
				background-color:#f1ec94;
			}
			&.sold:before{
				background-color:#f6b99a;
			}
		}
	}
	.btn{
		padding-left:20px;
		padding-right:20px;
		color:white;
		font-size:16px;
		background:#0e394b;
		display:flex;
		justify-content: center;
		align-items: center;
		width:fit-content;
		margin-left:auto;
		margin-right:auto;
		height:40px;
		text-decoration: none;  
		margin-top:0px;
	}
}