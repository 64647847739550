.blog {
    padding-top:70px;
    .title {
        color: #0e394b;
        font-size: 24px;
        font-weight: 300;
        display: flex;
        flex-direction: column;
        border-left: 5px solid #af1e23;
        padding-left: 10px;
        padding-bottom:10px;
        padding-top:10px;
    }
    .blog-content {
        display:flex;
        justify-content: space-between;
        flex-wrap:wrap;
        padding-top:60px;
        transition:5s;
        @media screen and (max-width:1199px) {
            justify-content: center;
        }
        .big-content {
            max-width:1200px;
            width:100%;
            height:320px;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            position:relative;
            text-decoration: none;
            @media screen and (min-width:1199px) {
                display:flex !important;
                max-width:1200px !important;
            }
            @media screen and (max-width:1199px) {
                display:none;
            }
            &:before {
                position:absolute;
                content:'';
                width:100%;
                height:320px;
                background: rgba(0, 0, 0, 0.5);
                left:0;
                right:0;
                padding:0;
            }
            .content-bg {
                @media screen and (min-width:1199px) {
                    display:block !important;
                    padding-left:95px !important;
                    position:relative;
                    z-index:1;
                }
                @media screen and (max-width:1199px) {
                    display:none;
                }
                .date {
                    font-size:16px;
                    color:white;
                    padding-bottom:40px;
                    padding-left:55px;
                    position:relative;
                    &:before {
                        position:absolute;
                        content:'';
                        height:34px;
                        width:34px;
                        background:url('../images/icon-date.png') no-repeat center;;
                        left:0;
                        top:-5px;
                    }
                }
                .title-blog {
                    color:white;
                    font-size:18px;
                    font-weight:500;
                    max-width:400px;
                    width:100%;
                    padding-bottom:30px;
					margin:0;
                }
                .btn-more {
                    padding-left:25px;
                    padding-right:25px;
                    color:white;
                    font-weight:300;
                    font-size:14px;
                    background:#af1e23;
                    width:fit-content;
                    display:flex;
                    align-items:center;
                    justify-content: center;
                    height:40px;
                }
            }
        }
        .small-content {
            width:100%;
            max-width:360px;
            height:320px;
            display:flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            position:relative;
            margin-top:15px;
            text-decoration: none;
			background-size:cover;
			background-position:center;
			background-repeat:no-repeat;
            &.rwd {
                display:none;
                @media screen and (max-width:1199px) {
                    display:flex;
                }
            }
            @media screen and (max-width:1199px) {
                margin:20px;
            }
            &:before {
                position:absolute;
                content:'';
                width:100%;
                height:320px;
                background: rgba(0, 0, 0, 0.5);
                left:0;
                right:0;
                padding:0;
            }
            .content {
                padding-left:40px;
                position:relative;
                z-index:1;
                &.rwd {
                    display:none;
                    @media screen and (max-width:1199px) {
                        display:block;
                    }
                }
                .date {
                    font-size:16px;
                    color:white;
                    padding-bottom:40px;
                    padding-left:55px;
                    position:relative;
                    &:before {
                        position:absolute;
                        content:'';
                        height:34px;
                        width:34px;
                        background:url('../images/icon-date.png') no-repeat center;;
                        left:0;
                        top:-5px;
                    }
                }
                .title-blog {
                    color:white;
                    font-size:24px;
                    font-weight:500;
                    max-width:400px;
                    width:100%;
                    padding-bottom:30px;
					margin:0;
                }
                .btn-more {
                    padding-left:25px;
                    padding-right:25px;
                    color:white;
                    font-weight:300;
                    font-size:14px;
                    background:#af1e23;
                    width:fit-content;
                    display:flex;
                    align-items:center;
                    justify-content: center;
                    height:40px;
                }
            }
        }
    }
    .other-news {
        width:100%;
        padding-top:40px;
        .title-others {
            font-weight:300;
            font-size:24px;
            color:black;
        }
        .blog-others {
            margin-top:20px;
            padding-top:20px;
            border-top:1px solid #e5e5e5;
            display:flex;
            justify-content: space-between;
            text-decoration: none;
            align-items: center;
            &:last-child {
                margin-bottom:40px;
            }
            @media screen and (max-width:991px) {
                flex-direction: column;
                justify-content: center;
            }
            img {
                max-width:360px;
                width:100%;
                height:auto;
                @media screen and (max-width:991px) {
                    margin-bottom:40px;
                }
            }
            .news {
                max-width:750px;
                width:100%;
                padding-left:30px;
                .date {
                    font-size:16px;
                    color:black;
                    padding-bottom:40px;
                    padding-left:55px;
                    position:relative;
                    &:before {
                        position:absolute;
                        content:'';
                        height:34px;
                        width:34px;
                        background:url('../images/icon-date.png') no-repeat center;;
                        left:0;
                        top:-5px;
                    }
                }
                .title-blog {
                    color:black;
                    font-size:24px;
                    font-weight:500;
                    max-width:400px;
                    width:100%;
                    padding-bottom:30px;
					margin:0;
                }
                .text-blog {
                    color:#0e394b;
                    font-size:14px;
                    padding-bottom:10px;
                }
                .btn-more {
                    color:#8b8989;
                    font-weight:300;
                    font-size:14px;
                }
            }
        }
    }
}