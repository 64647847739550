@charset "UTF-8";
body {
  font-family: 'Poppins', sans-serif;
  margin: 0 auto;
  overflow-x: hidden; }

.container {
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
  box-sizing: border-box;
  position: relative;
  width: 100%; }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 576px) and (max-width: 767px) {
    .container {
      max-width: 540px; } }

@keyframes spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 105px;
  padding-top: 10px;
  padding-bottom: 10px; }
  header .logo {
    width: 68px;
    height: 85px;
    display: block; }
  header .menu-btn {
    display: none;
    cursor: pointer; }
    header .menu-btn span {
      width: 40px;
      height: 6px;
      background: #0e394b;
      margin-bottom: 6px;
      display: block; }
      header .menu-btn span:last-child {
        margin-bottom: 0px; }
    @media screen and (max-width: 768px) {
      header .menu-btn {
        display: block; } }

.main-menu {
  list-style: none;
  display: flex;
  position: relative; }
  @media screen and (max-width: 769px) {
    .main-menu.no-rwd {
      display: none; } }
  .main-menu li {
    margin-right: 35px; }
    .main-menu li:last-child {
      margin-right: 0px; }
    .main-menu li a {
      text-decoration: none;
      font-weight: 500;
      text-transform: uppercase;
      color: #0e394b;
      padding-bottom: 39px;
      border-bottom: 2px solid none;
      transition: all 0.3s; }
      .main-menu li a:hover {
        color: #af1e23;
        border-bottom: 2px solid #af1e23; }
      .main-menu li a.active {
        color: #af1e23;
        border-bottom: 2px solid #af1e23; }
      .main-menu li a.click {
        border: none; }
    .main-menu li ul {
      list-style: none;
      top: 63px;
      margin-left: -15px;
      position: absolute;
      z-index: 1;
      padding-left: 15px;
      padding-right: 15px;
      background: white;
      width: fit-content;
      display: none; }
      .main-menu li ul .active {
        display: block; }
      .main-menu li ul li {
        padding-bottom: 10px; }
        .main-menu li ul li a {
          color: #0e394b;
          transition: all 0.5s;
          font-weight: 500;
          font-size: 14px; }
          .main-menu li ul li a:hover {
            color: #af1e23;
            border: none; }
  .main-menu.rwd {
    display: none; }
    @media screen and (max-width: 768px) {
      .main-menu.rwd {
        position: fixed;
        z-index: 5;
        left: 0;
        top: -16px;
        flex-direction: column;
        background: #0e394b;
        height: 100%;
        max-width: 250px;
        padding-right: 15px;
        width: 100%; }
        .main-menu.rwd li {
          margin-right: 10px;
          border-bottom: 1px solid rgba(255, 255, 255, 0.1); }
          .main-menu.rwd li a {
            color: white;
            display: block;
            padding-bottom: 20px;
            padding-top: 20px; }
            .main-menu.rwd li a.active {
              border: none; }
            .main-menu.rwd li a:hover {
              border: none; }
          .main-menu.rwd li ul {
            position: relative;
            top: 0;
            background: none; }
            .main-menu.rwd li ul li {
              margin-left: 30px;
              border-bottom: 0px solid rgba(255, 255, 255, 0.1); }
              .main-menu.rwd li ul li a {
                color: white;
                font-weight: 300; } }

#mainSlider {
  height: 632px; }
  #mainSlider .slider-section {
    height: 632px;
    background-position: center !important;
    background-repeat: no-repeat !important; }
    #mainSlider .slider-section .container {
      top: 200px;
      width: fit-content;
      background: rgba(36, 69, 82, 0.63);
      display: flex;
      padding: 25px; }
      #mainSlider .slider-section .container .slider-navigation {
        width: 35px;
        padding-right: 20px;
        margin-right: 20px;
        border-right: 3px solid white;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column; }
        #mainSlider .slider-section .container .slider-navigation .arrow-section {
          display: flex;
          justify-content: space-between; }
          #mainSlider .slider-section .container .slider-navigation .arrow-section .prev-slide {
            width: 5px;
            height: 9px;
            background: url("../images/arrow-prev.png") center no-repeat !important;
            cursor: pointer;
            padding: 10px; }
          #mainSlider .slider-section .container .slider-navigation .arrow-section .next-slide {
            width: 5px;
            height: 9px;
            background: url("../images/arrow-next.png") center no-repeat !important;
            cursor: pointer;
            padding: 10px; }
        #mainSlider .slider-section .container .slider-navigation .loader-slider {
          animation: spin 4.5s linear infinite; }
        #mainSlider .slider-section .container .slider-navigation .slider-number {
          color: white;
          font-size: 15px;
          text-align: center;
          margin-top: 10px;
          margin-bottom: 4px;
          font-weight: 300; }
      #mainSlider .slider-section .container .content {
        max-width: 710px;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        line-height: 1.2; }
        #mainSlider .slider-section .container .content .title {
          font-size: 50px;
          font-weight: 300;
          color: white;
          text-transform: uppercase;
          width: 100%;
          margin: 0;
          text-shadow: 2px 2px black; }
          @media screen and (max-width: 991px) {
            #mainSlider .slider-section .container .content .title {
              font-size: 40px; } }
          @media screen and (max-width: 767px) {
            #mainSlider .slider-section .container .content .title {
              font-size: 25px; } }
          @media screen and (max-width: 504px) {
            #mainSlider .slider-section .container .content .title {
              font-size: 20px; } }
        #mainSlider .slider-section .container .content .text {
          font-size: 40px;
          color: white;
          font-weight: 500;
          text-transform: uppercase;
          text-shadow: 2px 2px black; }
          @media screen and (max-width: 991px) {
            #mainSlider .slider-section .container .content .text {
              font-size: 35px; } }
          @media screen and (max-width: 767px) {
            #mainSlider .slider-section .container .content .text {
              font-size: 20px;
              padding-bottom: 20px; } }
          @media screen and (max-width: 504px) {
            #mainSlider .slider-section .container .content .text {
              font-size: 18px; } }
        #mainSlider .slider-section .container .content .button {
          font-size: 14px;
          padding-left: 15px;
          padding-right: 15px;
          border: 1px solid white;
          display: flex;
          align-items: center;
          height: 40px;
          color: white;
          font-weight: 300;
          cursor: pointer;
          text-decoration: none; }

.wrapper-one {
  background: #032939;
  width: 100%;
  min-height: 470px;
  height: 100%;
  position: relative; }
  @media screen and (max-width: 767px) {
    .wrapper-one {
      min-height: 435px;
      height: 435px; } }
  .wrapper-one:before {
    position: absolute;
    content: '';
    background: url("../images/wrapper-one-background.png") no-repeat center;
    max-width: 1153px;
    width: 100%;
    min-height: 470px;
    height: 100%;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    transition: 10s;
    z-index: 1; }
    @media screen and (max-width: 767px) {
      .wrapper-one:before {
        min-height: 435px;
        height: 435px; } }
  .wrapper-one .container {
    display: flex;
    position: absolute;
    left: 0;
    right: 0;
    top: -72.5px; }
    @media screen and (max-width: 767px) {
      .wrapper-one .container {
        flex-direction: column;
        position: relative;
        top: 0;
        justify-content: center; } }
    @media screen and (max-width: 767px) {
      .wrapper-one .container {
        padding: 0; } }
    .wrapper-one .container .box {
      max-width: 390px;
      width: 100%;
      background: #0e394b;
      height: 145px;
      display: flex;
      align-items: center;
      transition: 4s;
      transition-delay: 4s;
      position: relative;
      cursor: pointer;
      text-decoration: none;
      z-index: 2; }
      @media screen and (max-width: 767px) {
        .wrapper-one .container .box {
          max-width: 767px; } }
      .wrapper-one .container .box:nth-of-type(1):after {
        background: url("../images/wynajem.png") no-repeat; }
      .wrapper-one .container .box:nth-of-type(2):after {
        background: url("../images/sprzedaz.png") no-repeat; }
      .wrapper-one .container .box:nth-of-type(3):after {
        background: url("../images/lokalebiurowe.png") no-repeat; }
      .wrapper-one .container .box:nth-of-type(2) {
        background: #af1e23; }
      .wrapper-one .container .box:after {
        position: absolute;
        content: '';
        transition: 1s;
        position: absolute;
        content: '';
        max-width: 390px;
        width: 100%;
        height: 145px;
        opacity: 0; }
      .wrapper-one .container .box:hover:after {
        opacity: 1; }
      .wrapper-one .container .box:before {
        transition: 1s;
        position: absolute;
        content: '';
        max-width: 390px;
        width: 100%;
        height: 145px;
        z-index: 1; }
      .wrapper-one .container .box:hover:before {
        background: rgba(175, 30, 35, 0.35); }
      .wrapper-one .container .box .text {
        padding-left: 30px;
        color: white;
        font-size: 30px;
        font-weight: 500;
        text-transform: uppercase;
        display: flex;
        flex-direction: column;
        position: relative;
        z-index: 2;
        margin: 0; }
        .wrapper-one .container .box .text span {
          font-size: 24px;
          font-weight: 300; }

.wrapper-second {
  background: white;
  min-height: 370px;
  height: 100%;
  position: relative;
  z-index: 2; }
  .wrapper-second .container {
    position: absolute;
    top: -300px;
    left: 0;
    right: 0; }
    @media screen and (max-width: 991px) {
      .wrapper-second .container {
        position: relative;
        top: 0;
        margin-top: 40px; } }
    .wrapper-second .container .our-portfolio {
      widows: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      z-index: 2; }
      @media screen and (max-width: 500px) {
        .wrapper-second .container .our-portfolio {
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          padding-left: 45px; } }
      .wrapper-second .container .our-portfolio .text {
        color: white;
        font-size: 36px;
        font-weight: 300;
        display: flex;
        flex-direction: column;
        border-left: 5px solid #af1e23;
        padding-left: 10px; }
        .wrapper-second .container .our-portfolio .text h2 {
          font-size: 36px;
          font-weight: 300;
          margin: 0; }
        @media screen and (max-width: 991px) {
          .wrapper-second .container .our-portfolio .text {
            color: #0e394b; } }
        .wrapper-second .container .our-portfolio .text span {
          font-size: 17px; }
      .wrapper-second .container .our-portfolio .btn-more {
        font-size: 14px;
        font-weight: 500;
        color: white;
        text-decoration: none;
        display: flex;
        align-items: center; }
        @media screen and (max-width: 991px) {
          .wrapper-second .container .our-portfolio .btn-more {
            color: #0e394b; } }
        @media screen and (max-width: 500px) {
          .wrapper-second .container .our-portfolio .btn-more {
            padding-left: 15px;
            padding-top: 20px; } }
        .wrapper-second .container .our-portfolio .btn-more img {
          margin-left: 10px; }
    .wrapper-second .container .portfolio-list {
      display: flex;
      width: 100%;
      margin-top: 80px;
      position: relative;
      z-index: 2; }
      @media screen and (max-width: 991px) {
        .wrapper-second .container .portfolio-list {
          flex-direction: column; } }
      .wrapper-second .container .portfolio-list .box {
        max-width: 390px;
        width: 100%;
        text-decoration: none;
        position: relative;
        margin: 0 auto;
        padding-bottom: 30px; }
        .wrapper-second .container .portfolio-list .box:before {
          position: absolute;
          content: '';
          width: 10px;
          height: 20px;
          background: url("../images/portfolio-arrow.png") no-repeat center;
          bottom: 50px;
          right: 15px; }
        .wrapper-second .container .portfolio-list .box .picture-box {
          background-color: #afafaf !important;
          background-size: cover !important;
          background-position: center !important;
          background-repeat: no-repeat !important;
          max-width: 390px;
          width: 100%;
          height: 220px;
          position: relative; }
          .wrapper-second .container .portfolio-list .box .picture-box:before {
            max-width: 390px;
            width: 100%;
            height: 220px;
            transition: 1s;
            position: absolute;
            content: ''; }
          .wrapper-second .container .portfolio-list .box .picture-box:hover:before {
            background: rgba(175, 30, 35, 0.35); }
          .wrapper-second .container .portfolio-list .box .picture-box:after {
            width: 26px;
            height: 27px;
            position: absolute;
            content: '';
            background: url("../images/icon-loop.png") no-repeat center;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            opacity: 0;
            transition: 2s; }
          .wrapper-second .container .portfolio-list .box .picture-box:hover:after {
            opacity: 1.0; }
          .wrapper-second .container .portfolio-list .box .picture-box:hover {
            max-width: 390px;
            width: 100%;
            height: 220px; }
        .wrapper-second .container .portfolio-list .box .title {
          font-weight: 300;
          font-size: 18px;
          color: #0e394b;
          text-transform: uppercase;
          padding-left: 15px;
          margin: 30px 0 0; }
        .wrapper-second .container .portfolio-list .box .address {
          font-weight: 500;
          font-size: 18px;
          color: #0e394b;
          padding-left: 15px; }

.wrapper-third {
  background: #0e394b;
  width: 100%;
  position: relative;
  z-index: 3;
  padding-bottom: 70px; }
  @media screen and (max-width: 991px) {
    .wrapper-third {
      padding-top: 80px;
      padding-bottom: 80px; } }
  .wrapper-third .container .search-list-portfolio {
    position: absolute;
    top: -85px;
    max-height: 180px;
    height: 100%;
    border: 5px solid #e1e1e1;
    background: white;
    width: 100%; }
    .wrapper-third .container .search-list-portfolio:before {
      position: absolute;
      content: '';
      right: 10px;
      bottom: 12px;
      height: 31px;
      width: 40px;
      background: url(../images/loop-form.png) no-repeat center; }
    @media screen and (max-width: 991px) {
      .wrapper-third .container .search-list-portfolio {
        max-height: 260px;
        position: relative;
        top: 0;
        padding-top: 30px; } }
    @media screen and (max-width: 767px) {
      .wrapper-third .container .search-list-portfolio {
        height: 350px;
        padding-top: 0px;
        max-height: 260px;
        height: 100%; } }
    .wrapper-third .container .search-list-portfolio .choose-card {
      display: flex;
      width: 100%;
      position: absolute;
      top: -5px;
      left: -5px; }
      @media screen and (max-width: 991px) {
        .wrapper-third .container .search-list-portfolio .choose-card {
          background: white;
          left: 0; } }
      @media screen and (max-width: 767px) {
        .wrapper-third .container .search-list-portfolio .choose-card {
          flex-direction: column;
          top: 0;
          position: relative; } }
      .wrapper-third .container .search-list-portfolio .choose-card .box {
        max-width: 290px;
        width: 100%;
        height: 55px;
        display: flex;
        border: 1px solid #e1e1e1;
        background: white;
        justify-content: flex-start;
        align-items: center;
        border-left: 0px;
        cursor: pointer;
        transition: 0.35s; }
        .wrapper-third .container .search-list-portfolio .choose-card .box:last-child.active {
          border-right: 0; }
        @media screen and (max-width: 767px) {
          .wrapper-third .container .search-list-portfolio .choose-card .box {
            max-width: 900px;
            width: 100%; } }
        .wrapper-third .container .search-list-portfolio .choose-card .box:first-child {
          border-left: 5px solid #e1e1e1; }
          @media screen and (max-width: 991px) {
            .wrapper-third .container .search-list-portfolio .choose-card .box:first-child {
              border-left: 0px; } }
        .wrapper-third .container .search-list-portfolio .choose-card .box:nth-of-type(1) .img-icon {
          background: url("../images/sell-icon.png"); }
        .wrapper-third .container .search-list-portfolio .choose-card .box:nth-of-type(1).active .img-icon {
          background: url("../images/sell-icon-active.png"); }
        .wrapper-third .container .search-list-portfolio .choose-card .box:nth-of-type(2) .img-icon {
          background: url("../images/rent-icon.png"); }
        .wrapper-third .container .search-list-portfolio .choose-card .box:nth-of-type(2).active .img-icon {
          background: url("../images/rent-icon-active.png"); }
        .wrapper-third .container .search-list-portfolio .choose-card .box:nth-of-type(3) .img-icon {
          background: url("../images/local-icon.png"); }
        .wrapper-third .container .search-list-portfolio .choose-card .box:nth-of-type(3).active .img-icon {
          background: url("../images/local-icon-active.png"); }
        .wrapper-third .container .search-list-portfolio .choose-card .box .img-icon {
          margin-left: 20px;
          margin-right: 20px;
          background-repeat: no-repeat !important;
          background-position: center;
          width: 26px;
          height: 25px; }
        .wrapper-third .container .search-list-portfolio .choose-card .box .title {
          color: #0e394b;
          font-weight: 500;
          font-size: 16px; }
        .wrapper-third .container .search-list-portfolio .choose-card .box.active {
          border-top: 5px solid #af1e23;
          border-bottom: 0px;
          height: 51px; }
          .wrapper-third .container .search-list-portfolio .choose-card .box.active .title {
            color: #af1e23; }
    .wrapper-third .container .search-list-portfolio .choose-card-section {
      width: 100%;
      padding-left: 15px;
      padding-right: 15px;
      padding-top: 80px;
      background: white;
      box-sizing: border-box;
      transition: 2s;
      animation-duration: 0.9s;
      animation-delay: -0.75s; }
      .wrapper-third .container .search-list-portfolio .choose-card-section .forms {
        position: relative;
        z-index: -999;
        display: none;
        flex-wrap: wrap; }
        .wrapper-third .container .search-list-portfolio .choose-card-section .forms:first-child {
          display: flex;
          opacity: 1;
          min-height: 71px;
          height: 100%;
          z-index: 1; }
        @media screen and (max-width: 991px) {
          .wrapper-third .container .search-list-portfolio .choose-card-section .forms {
            justify-content: center; } }
        .wrapper-third .container .search-list-portfolio .choose-card-section .forms .input-box {
          margin-right: 10px; }
          .wrapper-third .container .search-list-portfolio .choose-card-section .forms .input-box:first-child {
            max-width: 350px; }
            @media screen and (max-width: 1199px) {
              .wrapper-third .container .search-list-portfolio .choose-card-section .forms .input-box:first-child {
                max-width: 185px; } }
            @media screen and (max-width: 991px) {
              .wrapper-third .container .search-list-portfolio .choose-card-section .forms .input-box:first-child {
                max-width: 350px; } }
          @media screen and (max-width: 991px) {
            .wrapper-third .container .search-list-portfolio .choose-card-section .forms .input-box {
              margin-bottom: 20px; } }
          .wrapper-third .container .search-list-portfolio .choose-card-section .forms .input-box:last-child {
            margin-right: 0px; }
          .wrapper-third .container .search-list-portfolio .choose-card-section .forms .input-box .text {
            color: #0e394b;
            font-size: 14px;
            font-weight: 500;
            text-transform: uppercase;
            padding-bottom: 10px; }
          .wrapper-third .container .search-list-portfolio .choose-card-section .forms .input-box .select-style {
            border: 1px solid #e1e1e1;
            height: 38px;
            display: flex;
            align-items: center;
            margin-right: 10px;
            width: 100%; }
            .wrapper-third .container .search-list-portfolio .choose-card-section .forms .input-box .select-style select {
              padding-left: 15px;
              padding-right: 15px;
              font-weight: bold;
              font-size: 14px;
              color: #0e394b;
              border: 0;
              background: none;
              position: relative;
              z-index: 1;
              max-width: 350px;
              width: 100%;
              height: 100%;
              outline: none; }
          .wrapper-third .container .search-list-portfolio .choose-card-section .forms .input-box .from-to {
            display: flex; }
            .wrapper-third .container .search-list-portfolio .choose-card-section .forms .input-box .from-to input {
              border: 1px solid #e1e1e1;
              height: 40px;
              padding-left: 15px;
              padding-right: 15px;
              font-weight: bold;
              font-size: 14px;
              color: #0e394b;
              width: 90px;
              box-sizing: border-box; }
              .wrapper-third .container .search-list-portfolio .choose-card-section .forms .input-box .from-to input:first-child {
                margin-right: 10px; }
        .wrapper-third .container .search-list-portfolio .choose-card-section .forms button {
          background: #0e394b;
          font-size: 14px;
          color: white;
          padding-left: 15px;
          padding-right: 15px;
          height: 40px;
          border: 0px;
          margin-top: 30px;
          cursor: pointer; }
  .wrapper-third .container .about-us {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-top: 200px; }
    @media screen and (max-width: 1199px) {
      .wrapper-third .container .about-us {
        justify-content: center;
        flex-wrap: wrap; } }
    @media screen and (max-width: 991px) {
      .wrapper-third .container .about-us {
        padding-top: 50px; } }
    @media screen and (max-width: 767px) {
      .wrapper-third .container .about-us {
        padding-top: 310px; } }
    .wrapper-third .container .about-us .left {
      width: 50%; }
      @media screen and (max-width: 1199px) {
        .wrapper-third .container .about-us .left {
          width: 100%; } }
      .wrapper-third .container .about-us .left .title {
        color: white;
        font-size: 36px;
        font-weight: 300;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        flex-direction: column;
        border-left: 5px solid #af1e23;
        padding-left: 10px; }
        .wrapper-third .container .about-us .left .title h1 {
          font-size: 36px;
          font-weight: 300;
          margin: 0; }
        .wrapper-third .container .about-us .left .title span {
          font-size: 17px; }
      .wrapper-third .container .about-us .left .text {
        color: white;
        font-weight: 300;
        padding-top: 40px;
        padding-left: 15px;
        max-width: 460px;
        width: 100%;
        font-size: 14px;
        line-height: 1.5; }
        @media screen and (max-width: 1199px) {
          .wrapper-third .container .about-us .left .text {
            max-width: 600px;
            width: 100%; } }
      .wrapper-third .container .about-us .left .btn-read-more {
        margin-top: 40px;
        height: 40px;
        display: flex;
        padding-left: 25px;
        padding-right: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: fit-content;
        text-decoration: none;
        font-size: 14px;
        color: white;
        border: 1px solid #516b76; }
    .wrapper-third .container .about-us .right {
      width: 50%;
      display: flex;
      flex-wrap: wrap; }
      @media screen and (max-width: 1199px) {
        .wrapper-third .container .about-us .right {
          width: 100%;
          padding-top: 100px; } }
      @media screen and (max-width: 991px) {
        .wrapper-third .container .about-us .right {
          justify-content: center; } }
      .wrapper-third .container .about-us .right .box {
        max-width: 183px;
        width: 100%;
        height: 183px;
        border: 1px solid #516b76;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column; }
        @media screen and (max-width: 1199px) {
          .wrapper-third .container .about-us .right .box {
            max-width: 153px;
            height: 153px; } }
        @media screen and (max-width: 991px) {
          .wrapper-third .container .about-us .right .box {
            max-width: 192px;
            height: 192px; } }
        .wrapper-third .container .about-us .right .box:nth-of-type(1) {
          border-left: 0px;
          border-bottom: 0px; }
          @media screen and (max-width: 1199px) {
            .wrapper-third .container .about-us .right .box:nth-of-type(1) {
              border-bottom: 1px solid #516b76; } }
          @media screen and (max-width: 991px) {
            .wrapper-third .container .about-us .right .box:nth-of-type(1) {
              border-bottom: 0px; } }
        .wrapper-third .container .about-us .right .box:nth-of-type(2) {
          border-left: 0px;
          border-bottom: 0px; }
          @media screen and (max-width: 1199px) {
            .wrapper-third .container .about-us .right .box:nth-of-type(2) {
              border-bottom: 1px solid #516b76; } }
          @media screen and (max-width: 991px) {
            .wrapper-third .container .about-us .right .box:nth-of-type(2) {
              border-bottom: 0px; } }
        .wrapper-third .container .about-us .right .box:nth-of-type(3) {
          border-left: 0px;
          border-bottom: 0px; }
          @media screen and (max-width: 1199px) {
            .wrapper-third .container .about-us .right .box:nth-of-type(3) {
              border-bottom: 1px solid #516b76; } }
          @media screen and (max-width: 991px) {
            .wrapper-third .container .about-us .right .box:nth-of-type(3) {
              border-bottom: 0px; } }
          @media screen and (max-width: 767px) {
            .wrapper-third .container .about-us .right .box:nth-of-type(3) {
              border-left: 1px solid #516b76; } }
        @media screen and (max-width: 767px) {
          .wrapper-third .container .about-us .right .box:nth-of-type(4) {
            border-bottom: 0px; } }
        .wrapper-third .container .about-us .right .box:nth-of-type(5) {
          border-left: 0px; }
          @media screen and (max-width: 767px) {
            .wrapper-third .container .about-us .right .box:nth-of-type(5) {
              border-left: 1px solid #516b76; } }
        .wrapper-third .container .about-us .right .box:nth-of-type(6) {
          border-left: 0px; }
        .wrapper-third .container .about-us .right .box .img {
          max-width: 190px;
          width: 100%;
          height: auto;
          display: block; }
        .wrapper-third .container .about-us .right .box div {
          width: 60px;
          height: 60px;
          display: flex;
          align-items: center;
          justify-content: center; }
        .wrapper-third .container .about-us .right .box .text {
          width: 100%;
          text-align: center;
          color: white;
          font-size: 14px;
          font-weight: 300;
          margin-top: 20px; }
          @media screen and (max-width: 1199px) {
            .wrapper-third .container .about-us .right .box .text {
              margin-top: 10px;
              font-size: 12px; } }
  .wrapper-third .container .our-portfolio-list {
    display: flex;
    justify-content: space-between;
    margin-top: 120px; }
    @media screen and (max-width: 991px) {
      .wrapper-third .container .our-portfolio-list {
        justify-content: center;
        flex-wrap: wrap; } }
    .wrapper-third .container .our-portfolio-list .box {
      max-width: 215px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      text-decoration: none; }
      @media screen and (max-width: 991px) {
        .wrapper-third .container .our-portfolio-list .box {
          max-width: 200px;
          margin-bottom: 50px; } }
      .wrapper-third .container .our-portfolio-list .box .title {
        font-size: 14px;
        color: white;
        font-weight: 300;
        margin: 0; }
      .wrapper-third .container .our-portfolio-list .box .text {
        font-size: 14px;
        color: white;
        font-weight: 300; }
      .wrapper-third .container .our-portfolio-list .box .date {
        color: #1b5168;
        font-weight: 500;
        font-size: 48px;
        position: relative; }
        .wrapper-third .container .our-portfolio-list .box .date:before {
          position: absolute;
          content: '';
          width: 2px;
          height: 50px;
          bottom: -77px;
          background: #032939;
          left: 50px; }
      .wrapper-third .container .our-portfolio-list .box .arrow {
        height: 30px;
        max-width: 170px;
        width: 100%;
        background: #032939;
        margin-bottom: 40px;
        margin-top: 10px;
        position: relative; }
        @media screen and (max-width: 1199px) {
          .wrapper-third .container .our-portfolio-list .box .arrow {
            display: none; } }
        .wrapper-third .container .our-portfolio-list .box .arrow:before {
          height: 23px;
          width: 23px;
          background: #0e394b;
          position: absolute;
          content: '';
          transform: rotate(45deg);
          left: -12px;
          top: 3px; }
        .wrapper-third .container .our-portfolio-list .box .arrow:after {
          height: 22px;
          width: 23px;
          background: #032939;
          position: absolute;
          content: '';
          transform: rotate(45deg);
          right: -13px;
          top: 4px; }
      .wrapper-third .container .our-portfolio-list .box img {
        border-radius: 50%;
        display: block;
        width: 106px;
        height: 106px;
        border: 1px solid grey;
        object-fit: cover; }
  .wrapper-third .container .our-portfolio-list-btn {
    color: white;
    font-size: 14px;
    text-align: center;
    font-weight: 300;
    text-decoration: none;
    margin-top: 30px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center; }
    .wrapper-third .container .our-portfolio-list-btn img {
      margin-left: 10px; }

.wrapper-fourth {
  background: #032939;
  padding-top: 75px;
  padding-bottom: 75px;
  width: 100%; }
  .wrapper-fourth .container .top-box {
    display: flex;
    justify-content: space-between;
    align-items: center; }
    @media screen and (max-width: 500px) {
      .wrapper-fourth .container .top-box {
        flex-direction: column;
        align-items: flex-start; } }
    .wrapper-fourth .container .top-box .title {
      color: white;
      font-size: 36px;
      font-weight: 300;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      flex-direction: column;
      border-left: 5px solid #af1e23;
      padding-left: 10px; }
      .wrapper-fourth .container .top-box .title h2 {
        font-size: 36px;
        font-weight: 300;
        margin: 0; }
      .wrapper-fourth .container .top-box .title span {
        font-size: 17px; }
      @media screen and (max-width: 500px) {
        .wrapper-fourth .container .top-box .title {
          margin-bottom: 30px; } }
    .wrapper-fourth .container .top-box .btn-more {
      font-size: 14px;
      font-weight: 500;
      color: white;
      text-decoration: none;
      display: flex;
      align-items: center; }
      .wrapper-fourth .container .top-box .btn-more img {
        margin-left: 10px; }
      @media screen and (max-width: 500px) {
        .wrapper-fourth .container .top-box .btn-more {
          padding-left: 15px; } }
  .wrapper-fourth .container .news-section {
    border: 1px solid #516b76;
    padding-top: 50px;
    padding-left: 0px;
    padding-bottom: 50px;
    box-sizing: border-box;
    margin-top: 83px;
    position: relative;
    min-height: 375px;
    height: 100%; }
    @media screen and (max-width: 1199px) {
      .wrapper-fourth .container .news-section {
        padding-bottom: 30px; } }
    .wrapper-fourth .container .news-section .left {
      width: 50%; }
      @media screen and (max-width: 1199px) {
        .wrapper-fourth .container .news-section .left {
          width: 46%;
          padding-left: 10px; } }
      @media screen and (max-width: 991px) {
        .wrapper-fourth .container .news-section .left {
          width: 100%;
          padding-left: 0px; } }
      .wrapper-fourth .container .news-section .left .date {
        color: #1b5168;
        font-size: 18px;
        font-weight: 500;
        width: 100%;
        max-width: 450px;
        text-align: center;
        padding-bottom: 40px;
        position: relative;
        margin-left: auto;
        margin-right: auto; }
        .wrapper-fourth .container .news-section .left .date:before {
          position: absolute;
          content: '';
          width: 60px;
          height: 3px;
          background: #1b5168;
          left: 0;
          right: 0;
          margin-left: auto;
          margin-right: auto;
          top: 45px; }
      .wrapper-fourth .container .news-section .left .title {
        font-size: 16px;
        color: white;
        font-weight: 500;
        max-width: 450px;
        width: 100%;
        text-align: center;
        padding-bottom: 25px;
        margin-left: auto;
        margin-right: auto; }
      .wrapper-fourth .container .news-section .left .text {
        width: 100%;
        max-width: 450px;
        text-align: center;
        font-size: 14px;
        font-weight: 300;
        color: white;
        margin-left: auto;
        margin-right: auto; }
      .wrapper-fourth .container .news-section .left .btn-more {
        border: 1px solid #1b5168;
        padding-left: 25px;
        padding-right: 25px;
        height: 40px;
        color: white;
        width: fit-content;
        font-size: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 300;
        margin-left: auto;
        margin-right: auto;
        margin-top: 30px;
        text-decoration: none; }
    .wrapper-fourth .container .news-section .right {
      width: 50%;
      position: absolute;
      top: -22px;
      right: 15px; }
      @media screen and (max-width: 1199px) {
        .wrapper-fourth .container .news-section .right {
          top: 10px; } }
      @media screen and (max-width: 991px) {
        .wrapper-fourth .container .news-section .right {
          display: none; } }
      .wrapper-fourth .container .news-section .right img {
        display: block;
        width: 555px;
        height: 424px;
        object-fit: cover; }

.partners_list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; }
  .partners_list .partner {
    display: block;
    margin: 5px; }
    .partners_list .partner img {
      max-width: 300px;
      max-height: 200px;
      object-fit: cover; }

.partner-list {
  padding-top: 80px;
  padding-bottom: 80px;
  background: white; }
  .partner-list .container {
    display: flex;
    justify-content: space-between; }
    @media screen and (max-width: 1199px) {
      .partner-list .container {
        flex-direction: column;
        justify-content: flex-start; } }
    .partner-list .container .title {
      color: #0e394b;
      font-size: 36px;
      font-weight: 300;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      flex-direction: column;
      border-left: 5px solid #af1e23;
      padding-left: 10px; }
      .partner-list .container .title h2 {
        font-size: 36px;
        font-weight: 300;
        margin: 0; }
      .partner-list .container .title span {
        font-size: 17px; }
    .partner-list .container .slider-partner {
      max-width: 800px;
      width: 100%; }
      @media screen and (max-width: 1199px) {
        .partner-list .container .slider-partner {
          margin-top: 50px;
          margin-left: auto;
          margin-right: auto; } }
      .partner-list .container .slider-partner .slick-prev {
        width: 10px;
        height: 20px;
        background: url("../images/partner-left.png"); }
        .partner-list .container .slider-partner .slick-prev:before {
          display: none; }
      .partner-list .container .slider-partner .slick-next {
        width: 10px;
        height: 20px;
        background: url("../images/partner-right.png"); }
        .partner-list .container .slider-partner .slick-next:before {
          display: none; }
      .partner-list .container .slider-partner .slick-list {
        max-width: 800px !important;
        width: 100%; }
        .partner-list .container .slider-partner .slick-list .section {
          display: flex !important;
          justify-content: center;
          align-items: center;
          height: 100px;
          max-width: 2220px;
          width: 100%; }
          .partner-list .container .slider-partner .slick-list .section img {
            width: 100%;
            max-width: 170px;
            height: auto;
            display: block;
            padding-left: 20px;
            padding-right: 20px; }

#fb-widget {
  display: flex;
  flex-direction: row;
  position: fixed;
  z-index: 10000;
  top: 15%;
  left: -300px;
  width: 350px;
  height: 500px;
  transition: left .2s linear; }
  #fb-widget.active {
    left: 0px; }
  #fb-widget .fb-page {
    flex-grow: 2; }
  #fb-widget .fb-button {
    width: 50px;
    height: 120px;
    background: url(../images/fb-widget.png) no-repeat;
    cursor: pointer; }

footer {
  border-top: 1px solid #e9edef;
  padding-top: 85px; }
  footer .container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; }
    footer .container .title {
      color: #0e394b;
      font-size: 36px;
      font-weight: 300;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      flex-direction: column;
      border-left: 5px solid #af1e23;
      padding-left: 10px;
      height: 100px; }
      footer .container .title h2 {
        font-size: 36px;
        font-weight: 300;
        margin: 0; }
      footer .container .title span {
        font-size: 17px; }
      @media screen and (max-width: 767px) {
        footer .container .title {
          width: 100%; } }
    footer .container .contact-info {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      width: 55%; }
      @media screen and (max-width: 767px) {
        footer .container .contact-info {
          width: 100%;
          margin-top: 50px; } }
      footer .container .contact-info .box {
        width: 50%;
        display: flex;
        align-items: center;
        margin-bottom: 30px; }
        @media screen and (max-width: 500px) {
          footer .container .contact-info .box {
            width: 100%; } }
        footer .container .contact-info .box .img {
          height: 50px;
          width: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          align-self: flex-start; }
        footer .container .contact-info .box .text {
          font-size: 16px;
          font-weight: 300;
          color: #0e394b;
          margin-left: 10px;
          width: 100%; }
  footer .map-and-form {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding-top: 50px;
    border-bottom: 1px solid #e9edef; }
    footer .map-and-form .google-maps {
      width: 50%;
      height: 375px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #0e394b;
      color: white; }
      @media screen and (max-width: 991px) {
        footer .map-and-form .google-maps {
          width: 100%;
          margin-bottom: 70px; } }
    footer .map-and-form .form-box {
      width: 50%; }
      @media screen and (max-width: 991px) {
        footer .map-and-form .form-box {
          width: 80%;
          margin-left: auto;
          margin-right: auto; } }
      footer .map-and-form .form-box form {
        width: 100%;
        max-width: 450px;
        padding-left: 130px; }
        @media screen and (max-width: 1199px) {
          footer .map-and-form .form-box form {
            padding-left: 50px; } }
        @media screen and (max-width: 991px) {
          footer .map-and-form .form-box form {
            max-width: 991px;
            padding-left: 0px; } }
        footer .map-and-form .form-box form input {
          width: 100%;
          border: 1px solid #e9edef;
          height: 43px;
          margin-bottom: 10px;
          font-weight: 300;
          font-size: 14px;
          color: #0e394b;
          padding-left: 15px;
          box-sizing: border-box; }
          footer .map-and-form .form-box form input::placeholder {
            color: #0e394b; }
        footer .map-and-form .form-box form textarea {
          width: 100%;
          border: 1px solid #e9edef;
          height: 190px;
          margin-bottom: 10px;
          font-weight: 300;
          font-size: 14px;
          color: #0e394b;
          padding-left: 15px;
          box-sizing: border-box;
          font-family: 'Poppins', sans-serif; }
          footer .map-and-form .form-box form textarea::placeholder {
            color: #0e394b; }
        footer .map-and-form .form-box form button {
          background: #0e394b;
          height: 45px;
          justify-content: center;
          align-items: center;
          color: white;
          font-size: 14px;
          padding-left: 25px;
          padding-right: 25px;
          border: 0px;
          font-weight: 500;
          cursor: pointer; }
  footer .footer-container-menu {
    padding-top: 50px;
    align-items: center; }
    footer .footer-container-menu .main-menu a.active {
      padding-bottom: 34px; }
    footer .footer-container-menu .main-menu a:hover {
      padding-bottom: 34px; }
  footer .created-by {
    border-top: 1px solid #e9edef; }
    footer .created-by .container.by {
      justify-content: space-between;
      align-items: center;
      height: 60px; }
      footer .created-by .container.by div {
        color: #6c6b6b;
        font-weight: 300;
        font-size: 12px; }
        footer .created-by .container.by div a {
          color: #af1e23;
          font-weight: 500;
          text-decoration: none; }

.banner {
  width: 100%;
  height: 265px; }

.blog {
  padding-top: 70px; }
  .blog .title {
    color: #0e394b;
    font-size: 24px;
    font-weight: 300;
    display: flex;
    flex-direction: column;
    border-left: 5px solid #af1e23;
    padding-left: 10px;
    padding-bottom: 10px;
    padding-top: 10px; }
  .blog .blog-content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-top: 60px;
    transition: 5s; }
    @media screen and (max-width: 1199px) {
      .blog .blog-content {
        justify-content: center; } }
    .blog .blog-content .big-content {
      max-width: 1200px;
      width: 100%;
      height: 320px;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      position: relative;
      text-decoration: none; }
      @media screen and (min-width: 1199px) {
        .blog .blog-content .big-content {
          display: flex !important;
          max-width: 1200px !important; } }
      @media screen and (max-width: 1199px) {
        .blog .blog-content .big-content {
          display: none; } }
      .blog .blog-content .big-content:before {
        position: absolute;
        content: '';
        width: 100%;
        height: 320px;
        background: rgba(0, 0, 0, 0.5);
        left: 0;
        right: 0;
        padding: 0; }
      @media screen and (min-width: 1199px) {
        .blog .blog-content .big-content .content-bg {
          display: block !important;
          padding-left: 95px !important;
          position: relative;
          z-index: 1; } }
      @media screen and (max-width: 1199px) {
        .blog .blog-content .big-content .content-bg {
          display: none; } }
      .blog .blog-content .big-content .content-bg .date {
        font-size: 16px;
        color: white;
        padding-bottom: 40px;
        padding-left: 55px;
        position: relative; }
        .blog .blog-content .big-content .content-bg .date:before {
          position: absolute;
          content: '';
          height: 34px;
          width: 34px;
          background: url("../images/icon-date.png") no-repeat center;
          left: 0;
          top: -5px; }
      .blog .blog-content .big-content .content-bg .title-blog {
        color: white;
        font-size: 18px;
        font-weight: 500;
        max-width: 400px;
        width: 100%;
        padding-bottom: 30px;
        margin: 0; }
      .blog .blog-content .big-content .content-bg .btn-more {
        padding-left: 25px;
        padding-right: 25px;
        color: white;
        font-weight: 300;
        font-size: 14px;
        background: #af1e23;
        width: fit-content;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px; }
    .blog .blog-content .small-content {
      width: 100%;
      max-width: 360px;
      height: 320px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      position: relative;
      margin-top: 15px;
      text-decoration: none;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat; }
      .blog .blog-content .small-content.rwd {
        display: none; }
        @media screen and (max-width: 1199px) {
          .blog .blog-content .small-content.rwd {
            display: flex; } }
      @media screen and (max-width: 1199px) {
        .blog .blog-content .small-content {
          margin: 20px; } }
      .blog .blog-content .small-content:before {
        position: absolute;
        content: '';
        width: 100%;
        height: 320px;
        background: rgba(0, 0, 0, 0.5);
        left: 0;
        right: 0;
        padding: 0; }
      .blog .blog-content .small-content .content {
        padding-left: 40px;
        position: relative;
        z-index: 1; }
        .blog .blog-content .small-content .content.rwd {
          display: none; }
          @media screen and (max-width: 1199px) {
            .blog .blog-content .small-content .content.rwd {
              display: block; } }
        .blog .blog-content .small-content .content .date {
          font-size: 16px;
          color: white;
          padding-bottom: 40px;
          padding-left: 55px;
          position: relative; }
          .blog .blog-content .small-content .content .date:before {
            position: absolute;
            content: '';
            height: 34px;
            width: 34px;
            background: url("../images/icon-date.png") no-repeat center;
            left: 0;
            top: -5px; }
        .blog .blog-content .small-content .content .title-blog {
          color: white;
          font-size: 24px;
          font-weight: 500;
          max-width: 400px;
          width: 100%;
          padding-bottom: 30px;
          margin: 0; }
        .blog .blog-content .small-content .content .btn-more {
          padding-left: 25px;
          padding-right: 25px;
          color: white;
          font-weight: 300;
          font-size: 14px;
          background: #af1e23;
          width: fit-content;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 40px; }
  .blog .other-news {
    width: 100%;
    padding-top: 40px; }
    .blog .other-news .title-others {
      font-weight: 300;
      font-size: 24px;
      color: black; }
    .blog .other-news .blog-others {
      margin-top: 20px;
      padding-top: 20px;
      border-top: 1px solid #e5e5e5;
      display: flex;
      justify-content: space-between;
      text-decoration: none;
      align-items: center; }
      .blog .other-news .blog-others:last-child {
        margin-bottom: 40px; }
      @media screen and (max-width: 991px) {
        .blog .other-news .blog-others {
          flex-direction: column;
          justify-content: center; } }
      .blog .other-news .blog-others img {
        max-width: 360px;
        width: 100%;
        height: auto; }
        @media screen and (max-width: 991px) {
          .blog .other-news .blog-others img {
            margin-bottom: 40px; } }
      .blog .other-news .blog-others .news {
        max-width: 750px;
        width: 100%;
        padding-left: 30px; }
        .blog .other-news .blog-others .news .date {
          font-size: 16px;
          color: black;
          padding-bottom: 40px;
          padding-left: 55px;
          position: relative; }
          .blog .other-news .blog-others .news .date:before {
            position: absolute;
            content: '';
            height: 34px;
            width: 34px;
            background: url("../images/icon-date.png") no-repeat center;
            left: 0;
            top: -5px; }
        .blog .other-news .blog-others .news .title-blog {
          color: black;
          font-size: 24px;
          font-weight: 500;
          max-width: 400px;
          width: 100%;
          padding-bottom: 30px;
          margin: 0; }
        .blog .other-news .blog-others .news .text-blog {
          color: #0e394b;
          font-size: 14px;
          padding-bottom: 10px; }
        .blog .other-news .blog-others .news .btn-more {
          color: #8b8989;
          font-weight: 300;
          font-size: 14px; }

#investments-background {
  position: relative; }
  #investments-background:before {
    position: absolute;
    content: '';
    background: url("../images/investment-background.png");
    width: 100%;
    height: 100%;
    bottom: 0;
    left: 0;
    z-index: -1; }

.investments {
  padding-top: 50px;
  padding-bottom: 50px;
  background: white; }
  .investments .title {
    color: #0e394b;
    font-size: 24px;
    font-weight: 300;
    display: flex;
    flex-direction: column;
    border-left: 5px solid #af1e23;
    padding-left: 10px;
    padding-bottom: 10px;
    padding-top: 10px; }
  .investments .text {
    padding-top: 80px; }
  .investments .investments-content {
    width: 100%;
    padding-top: 30px; }
    .investments .investments-content img {
      width: 99%;
      margin: auto; }
    .investments .investments-content .investments-title {
      color: #0e394b;
      font-size: 24px;
      font-weight: 300;
      padding-top: 25px;
      padding-bottom: 25px;
      margin: 0; }
    .investments .investments-content .investments-text {
      color: #0e394b;
      font-size: 14px;
      font-weight: 500;
      padding-bottom: 25px; }
    .investments .investments-content .btn-more {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-left: 25px;
      padding-right: 25px;
      color: white;
      background: #0e394b;
      height: 40px;
      width: fit-content;
      text-decoration: none;
      font-size: 14px; }

.realisations {
  padding-top: 50px;
  padding-bottom: 50px;
  background: white; }
  .realisations .title {
    color: #0e394b;
    font-size: 24px;
    font-weight: 300;
    display: flex;
    flex-direction: column;
    border-left: 5px solid #af1e23;
    padding-left: 10px;
    padding-bottom: 10px;
    padding-top: 10px;
    margin: 0; }
  .realisations .text {
    color: #0e394b;
    font-weight: 500;
    font-size: 14px;
    padding-bottom: 40px;
    padding-top: 40px; }
  .realisations .realizations_list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; }
    .realisations .realizations_list .realization {
      text-decoration: none;
      padding-right: 15px;
      margin-bottom: 45px;
      max-width: 360px;
      width: 100%;
      position: relative; }
      .realisations .realizations_list .realization:before {
        max-width: 360px;
        width: 100%;
        height: 250px;
        transition: 1s;
        position: absolute;
        content: ''; }
      .realisations .realizations_list .realization:hover:before {
        background: rgba(175, 30, 35, 0.35); }
      .realisations .realizations_list .realization:after {
        width: 26px;
        height: 27px;
        position: absolute;
        content: '';
        top: 115px;
        left: 0;
        right: 0;
        margin: auto;
        opacity: 0;
        transition: 2s;
        background: url("../images/icon-loop.png") no-repeat center; }
      .realisations .realizations_list .realization:hover:after {
        opacity: 1.0; }
      .realisations .realizations_list .realization:nth-of-type(3n+3) {
        padding-right: 0px; }
        @media screen and (max-width: 1199px) {
          .realisations .realizations_list .realization:nth-of-type(3n+3) {
            padding-right: 15px; } }
      @media screen and (max-width: 1199px) {
        .realisations .realizations_list .realization:nth-of-type(2n+2) {
          padding-right: 0px; } }
      @media screen and (max-width: 991px) {
        .realisations .realizations_list .realization {
          padding-right: 0px; } }
      .realisations .realizations_list .realization img {
        max-width: 360px;
        width: 100%;
        height: 250px; }
      .realisations .realizations_list .realization .realization_details {
        padding-top: 25px;
        position: relative; }
        .realisations .realizations_list .realization .realization_details:before {
          position: absolute;
          content: '';
          width: 10px;
          height: 20px;
          background: url(../images/portfolio-arrow.png) no-repeat center;
          bottom: 50px;
          right: 15px; }
        .realisations .realizations_list .realization .realization_details .realization_name {
          font-size: 18px;
          color: #0e394b;
          font-weight: 300;
          margin: 0; }
        .realisations .realizations_list .realization .realization_details .realization_address {
          font-size: 18px;
          color: #0e394b;
          font-weight: 500; }
        .realisations .realizations_list .realization .realization_details .realization_year {
          font-size: 14px;
          color: #af1e23;
          font-weight: 300; }

.textpage {
  padding-top: 50px;
  padding-bottom: 50px;
  background: white; }
  .textpage .title {
    color: #0e394b;
    font-size: 24px;
    font-weight: 300;
    display: flex;
    flex-direction: column;
    border-left: 5px solid #af1e23;
    padding-left: 10px;
    padding-bottom: 10px;
    padding-top: 10px; }
  .textpage .text {
    color: #0e394b;
    font-size: 14px;
    font-weight: 500; }

.gallery {
  padding-top: 50px;
  padding-bottom: 50px;
  background: white; }
  .gallery .title {
    color: #0e394b;
    font-size: 24px;
    font-weight: 300;
    display: flex;
    flex-direction: column;
    border-left: 5px solid #af1e23;
    padding-left: 10px;
    padding-bottom: 10px;
    padding-top: 10px;
    margin: 0; }
  .gallery .wrapper {
    display: flex;
    padding-top: 50px; }
    @media screen and (max-width: 991px) {
      .gallery .wrapper {
        flex-direction: column;
        flex-flow: row wrap; } }
    .gallery .wrapper .left {
      max-width: 750px;
      width: 100%; }
      @media screen and (max-width: 991px) {
        .gallery .wrapper .left {
          order: 2; } }
      .gallery .wrapper .left .description {
        width: 100%;
        padding-bottom: 40px; }
        .gallery .wrapper .left .description .date {
          color: #af1e23;
          font-size: 14px;
          font-weight: 500; }
        .gallery .wrapper .left .description .title-description {
          color: #0e394b;
          font-size: 24px;
          font-weight: 300;
          padding-bottom: 30px; }
        .gallery .wrapper .left .description .text {
          font-size: 14px;
          font-weight: 500;
          color: #0e394b; }
    .gallery .wrapper .right {
      max-width: 290px;
      width: 100%;
      list-style: none;
      display: flex;
      flex-direction: column; }
      @media screen and (max-width: 991px) {
        .gallery .wrapper .right {
          order: 1;
          max-width: 900px;
          flex-direction: initial;
          flex-wrap: wrap; } }
      .gallery .wrapper .right li {
        width: 100%;
        background: #0e394b;
        padding-left: 30px;
        box-sizing: border-box;
        border-bottom: 1px solid white; }
        .gallery .wrapper .right li.active, .gallery .wrapper .right li:hover {
          background: #3a7a94; }
        @media screen and (max-width: 991px) {
          .gallery .wrapper .right li {
            background-size: 100px 100px;
            max-width: 300px;
            background-repeat: no-repeat;
            background-size: cover; } }
        .gallery .wrapper .right li a {
          color: white;
          display: flex;
          align-items: center;
          padding-top: 25px;
          padding-bottom: 25px;
          text-decoration: none;
          font-size: 18px;
          font-weight: 300; }
        .gallery .wrapper .right li ul {
          background: #0f627e;
          box-sizing: border-box;
          padding-left: 10px;
          margin-left: -30px;
          list-style-position: outside;
          list-style: none;
          overflow-y: hidden;
          max-height: 0px;
          padding-top: 0px;
          padding-bottom: 0px;
          transition-property: all;
          transition-duration: 0.5s;
          transition-timing-function: cubic-bezier(0.5, 0.5, 0.5, 0.5); }
          .gallery .wrapper .right li ul li {
            background: #0f627e;
            border: none;
            position: relative; }
            .gallery .wrapper .right li ul li:before {
              position: absolute;
              content: '';
              background: #65c2e1;
              height: 5px;
              width: 5px;
              border-radius: 20px;
              left: 10px;
              top: 10px; }
            .gallery .wrapper .right li ul li a {
              color: #65c2e1;
              font-size: 15px;
              font-weight: 300;
              padding-top: 2px;
              padding-bottom: 2px; }
              .gallery .wrapper .right li ul li a:hover {
                color: white; }
        .gallery .wrapper .right li.active ul {
          padding-top: 25px;
          padding-bottom: 25px;
          max-height: 500px; }
  .gallery .gallery-section {
    display: flex;
    flex-wrap: wrap;
    padding-top: 50px; }
    .gallery .gallery-section a {
      max-width: 360px;
      width: 100%;
      height: auto;
      position: relative;
      transition: 5s;
      margin: 7px; }
      .gallery .gallery-section a:hover:before {
        max-width: 360px;
        width: 100%;
        height: 250px;
        background: rgba(174, 30, 34, 0.6);
        position: absolute;
        content: ''; }
      .gallery .gallery-section a:hover:after {
        position: absolute;
        content: '';
        width: 26px;
        height: 27px;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        top: 48%;
        background: url("../images/icon-loop.png") no-repeat center; }
      .gallery .gallery-section a img {
        max-width: 360px;
        width: 100%;
        height: 250px;
        object-fit: cover; }

.team {
  padding-top: 100px;
  padding-bottom: 50px;
  background: white; }
  .team .title {
    color: #0e394b;
    font-size: 24px;
    font-weight: 300;
    display: flex;
    flex-direction: column;
    border-left: 5px solid #af1e23;
    padding-left: 10px;
    padding-bottom: 10px;
    padding-top: 10px; }
  .team .wrapper {
    display: flex;
    padding-top: 50px; }
    @media screen and (max-width: 991px) {
      .team .wrapper {
        flex-direction: column;
        flex-flow: row wrap; } }
    .team .wrapper .left {
      max-width: 750px;
      width: 100%;
      transition: 5s;
      display: flex;
      flex-wrap: wrap; }
      @media screen and (max-width: 991px) {
        .team .wrapper .left {
          order: 2;
          padding-top: 50px; } }
      .team .wrapper .left .box-team {
        width: 210px;
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 50px;
        box-sizing: border-box; }
        .team .wrapper .left .box-team img {
          width: 160px;
          height: auto;
          margin: auto;
          display: block; }
        .team .wrapper .left .box-team .name-team {
          font-size: 16px;
          color: #0e394b;
          text-align: center;
          width: 100%;
          padding-top: 30px;
          font-weight: 500; }
        .team .wrapper .left .box-team .name-position {
          font-size: 16px;
          color: #0e394b;
          text-align: center;
          width: 100%;
          font-weight: 300; }
        .team .wrapper .left .box-team .number-phone {
          font-size: 16px;
          color: #ae1e22;
          text-align: center;
          width: 100%;
          font-weight: 300; }
    .team .wrapper .right {
      max-width: 290px;
      width: 100%;
      list-style: none;
      display: flex;
      flex-direction: column; }
      @media screen and (max-width: 991px) {
        .team .wrapper .right {
          order: 1;
          max-width: 900px;
          flex-direction: initial;
          flex-wrap: wrap; } }
      .team .wrapper .right li {
        width: 100%;
        background: #0e394b;
        padding-left: 30px;
        box-sizing: border-box;
        border-bottom: 1px solid white; }
        @media screen and (max-width: 991px) {
          .team .wrapper .right li {
            background-size: 100px 100px;
            max-width: 300px;
            background-repeat: no-repeat;
            background-size: cover; } }
        .team .wrapper .right li a {
          color: white;
          display: flex;
          align-items: center;
          padding-top: 25px;
          padding-bottom: 25px;
          text-decoration: none;
          font-size: 18px;
          font-weight: 300; }
        .team .wrapper .right li ul {
          background: #0f627e;
          box-sizing: border-box;
          padding-left: 10px;
          margin-left: -30px;
          list-style-position: outside;
          list-style: none;
          overflow-y: hidden;
          max-height: 0px;
          padding-top: 0px;
          padding-bottom: 0px;
          transition-property: all;
          transition-duration: 0.5s;
          transition-timing-function: cubic-bezier(0.5, 0.5, 0.5, 0.5); }
          .team .wrapper .right li ul li {
            background: #0f627e;
            border: none;
            position: relative; }
            .team .wrapper .right li ul li:before {
              position: absolute;
              content: '';
              background: #65c2e1;
              height: 5px;
              width: 5px;
              border-radius: 20px;
              left: 10px;
              top: 10px; }
            .team .wrapper .right li ul li a {
              color: #65c2e1;
              font-size: 15px;
              font-weight: 300;
              padding-top: 2px;
              padding-bottom: 2px; }
              .team .wrapper .right li ul li a:hover {
                color: white; }
        .team .wrapper .right li.active ul {
          padding-top: 25px;
          padding-bottom: 25px;
          max-height: 500px; }

.team-container {
  display: flex;
  flex-wrap: wrap; }
  .team-container .box-team {
    width: 210px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 50px;
    box-sizing: border-box; }
    .team-container .box-team img {
      width: 160px;
      height: auto;
      margin: auto;
      display: block; }
    .team-container .box-team .name-team {
      font-size: 16px;
      color: #0e394b;
      text-align: center;
      width: 100%;
      padding-top: 30px;
      font-weight: 500; }
    .team-container .box-team .name-position {
      font-size: 16px;
      color: #0e394b;
      text-align: center;
      width: 100%;
      font-weight: 300; }
    .team-container .box-team .number-phone {
      font-size: 16px;
      color: #ae1e22;
      text-align: center;
      width: 100%;
      font-weight: 300; }

.blog-text {
  margin-top: 80px;
  padding-bottom: 50px; }
  .blog-text .title {
    color: #0e394b;
    font-size: 24px;
    font-weight: 500;
    line-height: 1.5;
    margin: 0; }
  .blog-text .date {
    font-size: 16px;
    color: #0e394b;
    padding-bottom: 40px;
    padding-left: 55px;
    position: relative;
    margin-top: 40px; }
    .blog-text .date:before {
      position: absolute;
      content: '';
      height: 34px;
      width: 34px;
      background: url(../images/icon-date.png) no-repeat center;
      left: 0;
      top: -6px; }
  .blog-text .text-page {
    color: #0e394b;
    font-size: 14px; }

.contact {
  padding-top: 70px;
  padding-bottom: 50px; }
  .contact .title {
    color: #0e394b;
    font-size: 24px;
    font-weight: 300;
    display: flex;
    flex-direction: column;
    border-left: 5px solid #af1e23;
    padding-left: 10px;
    padding-bottom: 10px;
    padding-top: 10px; }
  .contact .wrapper {
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid #d6dee1;
    padding-bottom: 50px; }
    .contact .wrapper:last-child {
      border-bottom: none; }
    .contact .wrapper .left {
      width: 50%; }
      @media screen and (max-width: 991px) {
        .contact .wrapper .left {
          width: 100%; } }
      .contact .wrapper .left.no-map {
        width: 100%;
        display: flex;
        padding-top: 30px;
        padding-bottom: 30px; }
        @media screen and (max-width: 991px) {
          .contact .wrapper .left.no-map {
            width: 100%;
            flex-wrap: wrap; } }
        .contact .wrapper .left.no-map .information {
          max-width: 300px;
          width: 100%;
          border: none;
          margin-bottom: 40px; }
          @media screen and (max-width: 991px) {
            .contact .wrapper .left.no-map .information {
              max-width: 1000px;
              width: 50%;
              padding: 0px;
              justify-content: center; } }
  @media screen and (max-width: 991px) and (max-width: 767px) {
    .contact .wrapper .left.no-map .information {
      width: 100%; } }
          @media screen and (max-width: 991px) {
              .contact .wrapper .left.no-map .information .box {
                width: 80%; } }
    @media screen and (max-width: 991px) and (max-width: 767px) {
      .contact .wrapper .left.no-map .information .box {
        width: 100%; } }
          @media screen and (max-width: 991px) {
                .contact .wrapper .left.no-map .information .box.tel span {
                  margin-left: 37px; } }
      @media screen and (max-width: 991px) and (max-width: 767px) {
        .contact .wrapper .left.no-map .information .box.tel span:nth-of-type(2) {
          margin-left: -23px; } }
          .contact .wrapper .left.no-map .information .text-box {
            color: #0e394b;
            font-size: 15px;
            font-weight: bold;
            max-width: 300px;
            width: 100%;
            min-height: 70px;
            margin: 0; }
      .contact .wrapper .left .text {
        color: #0e394b;
        font-size: 15px;
        font-weight: bold;
        max-width: 300px;
        width: 100%;
        padding-bottom: 40px;
        padding-top: 40px;
        margin: 0; }
      .contact .wrapper .left .information {
        max-width: 490px;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding-bottom: 20px;
        padding-right: 65px;
        border-bottom: 1px solid #d6dee1; }
        @media screen and (max-width: 991px) {
          .contact .wrapper .left .information {
            max-width: 1000px; } }
        @media screen and (max-width: 767px) {
          .contact .wrapper .left .information {
            flex-direction: column; } }
        .contact .wrapper .left .information:last-child {
          border-bottom: none; }
        .contact .wrapper .left .information .box {
          max-width: 195px;
          width: 100%;
          padding-bottom: 3px;
          color: #0e394b;
          font-weight: 300;
          font-size: 15px; }
          @media screen and (max-width: 991px) {
            .contact .wrapper .left .information .box {
              max-width: 991px;
              width: 50%; } }
          .contact .wrapper .left .information .box span {
            font-weight: bold;
            margin-right: 10px; }
          .contact .wrapper .left .information .box.tel {
            display: flex;
            flex-wrap: wrap; }
            .contact .wrapper .left .information .box.tel span {
              width: 100%;
              font-weight: 300;
              margin-left: 30px; }
              .contact .wrapper .left .information .box.tel span:nth-of-type(1) {
                width: 10%;
                font-weight: bold;
                margin-left: 0; }
              .contact .wrapper .left .information .box.tel span:nth-of-type(2) {
                width: 75%;
                margin-left: 0; }
    .contact .wrapper .right {
      width: 50%;
      padding-left: 30px;
      box-sizing: border-box;
      display: flex;
      align-items: center; }
      @media screen and (max-width: 991px) {
        .contact .wrapper .right {
          width: 100%;
          padding-left: 0px; } }
      .contact .wrapper .right .google-maps {
        width: 100%;
        height: 400px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #0e394b;
        color: white; }

.question-form {
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  top: 0;
  z-index: 9999;
  transition: 5s;
  display: none;
  align-items: center;
  justify-items: center; }
  .question-form.active {
    display: flex; }
  .question-form .container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 1000;
    background: white;
    padding-top: 80px;
    padding-bottom: 80px;
    box-shadow: 1px 2px 5px 3px rgba(0, 0, 0, 0.2);
    border-radius: 10px; }
    .question-form .container .close {
      position: absolute;
      font-size: 30px;
      right: 40px;
      top: 20px;
      font-weight: 500;
      color: #032939;
      cursor: pointer; }
    .question-form .container .title {
      font-size: 20px;
      color: black;
      text-align: center;
      text-transform: uppercase;
      padding-bottom: 10px; }
    .question-form .container .msg {
      padding-bottom: 20px;
      color: red; }
    .question-form .container form {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 100%; }
      .question-form .container form input {
        max-width: 700px;
        width: 100%;
        height: 45px;
        border: 1px solid #d7d7d7;
        color: black;
        margin-bottom: 10px;
        box-sizing: border-box;
        padding-left: 15px;
        font-size: 18px;
        box-sizing: border-box;
        border-radius: 10px;
        outline: none; }
        .question-form .container form input::placeholder {
          color: black; }
      .question-form .container form textarea {
        max-width: 700px;
        width: 100%;
        min-height: 95px;
        border: 1px solid #d7d7d7;
        color: black;
        margin-bottom: 30px;
        box-sizing: border-box;
        padding-left: 15px;
        font-size: 18px;
        padding-top: 15px;
        border-radius: 10px;
        outline: none; }
        .question-form .container form textarea::placeholder {
          color: black; }
      .question-form .container form button {
        max-width: 500px;
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #0e394b;
        border: none;
        color: white;
        font-size: 18px;
        margin-top: 50px;
        cursor: pointer; }

.vis-main .banner {
  display: none; }
  .vis-main .banner.active {
    display: block; }

.vis-main .svg-container {
  position: relative;
  margin: 10px 0; }
  .vis-main .svg-container #svg {
    width: 100%;
    min-height: 300px;
    height: auto; }
    .vis-main .svg-container #svg.load {
      background-image: url("../images/svg_loader.gif");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 150px 150px; }
    .vis-main .svg-container #svg svg {
      display: block;
      width: 100%;
      height: auto;
      margin: auto; }
    .vis-main .svg-container #svg.building.not-found {
      display: none; }
    .vis-main .svg-container #svg.floor.not-found {
      background-color: #b1b1b1;
      background-image: url("../images/svg_404.png");
      background-repeat: no-repeat;
      background-position: center; }
  .vis-main .svg-container .svg-tooltip {
    display: none;
    position: absolute;
    left: 0;
    top: 0;
    text-align: center;
    padding: 20px;
    font-size: 14px;
    color: white;
    background-color: black; }
    .vis-main .svg-container .svg-tooltip:after {
      content: '';
      position: absolute;
      width: 20px;
      height: 20px;
      left: calc(50% - 10px);
      bottom: -10px;
      background-color: black;
      transform: rotateZ(45deg); }
    .vis-main .svg-container .svg-tooltip span {
      color: #efb518; }
    .vis-main .svg-container .svg-tooltip .svg-click-info {
      margin: 10px 0 0; }

.vis-main .vis-data.vis-building .vis-object {
  fill: transparent;
  opacity: 0.8; }

.vis-main .vis-data.vis-building .vis-object:hover {
  fill: #40ab19; }

.vis-main .vis-data.vis-building .vis-sold:hover {
  fill: #af1e23; }

.vis-main .vis-data.vis-building .vis-disabled:hover {
  fill: transparent; }

.vis-main .vis-data.vis-floor .vis-object {
  fill: #c3df81; }

.vis-main .vis-data.vis-floor .vis-sold {
  fill: #f6b99a; }

.vis-main .vis-data.vis-floor .vis-rent {
  fill: #f1ec94; }

.vis-main .vis-data.vis-floor .vis-disabled {
  fill: transparent; }

.vis-main .vis-data .vis-object:hover {
  cursor: pointer; }

.vis-main .vis-data .vis-disabled:hover {
  cursor: default; }

@media screen and (max-width: 991px) {
  .vis-main .vis-labels .vis-label-rect {
    width: 550px;
    height: 50px; }
  .vis-main .vis-labels .vis-label-longrect {
    width: 950px;
    height: 50px; }
  .vis-main .vis-labels .vis-label-text {
    font-size: 35px; } }

.bulding-list {
  width: 100%;
  padding-bottom: 50px; }
  .bulding-list tr.pointer {
    cursor: pointer; }
  .bulding-list .map .title-page {
    margin-bottom: 15px; }
  .bulding-list .map .map-content {
    display: flex;
    flex-direction: row;
    margin-top: 15px; }
    @media screen and (max-width: 991px) {
      .bulding-list .map .map-content {
        display: block; } }
    .bulding-list .map .map-content .left {
      width: 100%; }
      .bulding-list .map .map-content .left .google-maps {
        width: 100%;
        height: 400px;
        background: #0e394b;
        color: white; }
    .bulding-list .map .map-content .right {
      max-width: 25%;
      width: 100%;
      padding-left: 40px; }
      @media screen and (max-width: 991px) {
        .bulding-list .map .map-content .right {
          max-width: 100%;
          padding-left: 0;
          margin-top: 15px; } }
  .bulding-list .wrapper {
    height: 230px;
    width: 100%;
    background: #032939;
    margin-top: -7px;
    margin-bottom: 50px; }
    .bulding-list .wrapper .container {
      padding-top: 50px; }
      .bulding-list .wrapper .container .title-page {
        color: white !important;
        font-size: 24px;
        font-weight: 300;
        display: flex;
        flex-direction: column;
        border-left: 3px solid #af1e23;
        padding-left: 10px;
        padding-top: 5px;
        padding-bottom: 5px; }
      .bulding-list .wrapper .container .search-list-portfolio {
        position: absolute;
        top: 126px;
        max-height: 180px;
        height: 100%;
        border: 5px solid #e1e1e1;
        background: white;
        width: 100%; }
        .bulding-list .wrapper .container .search-list-portfolio:before {
          position: absolute;
          content: '';
          right: 10px;
          bottom: 12px;
          height: 31px;
          width: 40px;
          background: url(../images/loop-form.png) no-repeat center; }
        @media screen and (max-width: 991px) {
          .bulding-list .wrapper .container .search-list-portfolio {
            max-height: 260px;
            position: relative;
            top: 0;
            padding-top: 30px; } }
        @media screen and (max-width: 767px) {
          .bulding-list .wrapper .container .search-list-portfolio {
            height: 350px;
            padding-top: 0px;
            max-height: 260px;
            height: 100%; } }
        .bulding-list .wrapper .container .search-list-portfolio .choose-card {
          display: flex;
          width: 100%;
          position: absolute;
          top: -5px;
          left: -5px; }
          @media screen and (max-width: 991px) {
            .bulding-list .wrapper .container .search-list-portfolio .choose-card {
              background: white;
              left: 0; } }
          @media screen and (max-width: 767px) {
            .bulding-list .wrapper .container .search-list-portfolio .choose-card {
              flex-direction: column;
              top: 0;
              position: relative; } }
          .bulding-list .wrapper .container .search-list-portfolio .choose-card .box {
            max-width: 290px;
            width: 100%;
            height: 55px;
            display: flex;
            border: 1px solid #e1e1e1;
            background: white;
            justify-content: flex-start;
            align-items: center;
            border-left: 0px;
            cursor: pointer;
            transition: 0.35s; }
            .bulding-list .wrapper .container .search-list-portfolio .choose-card .box:last-child.active {
              border-right: 0; }
            @media screen and (max-width: 767px) {
              .bulding-list .wrapper .container .search-list-portfolio .choose-card .box {
                max-width: 900px;
                width: 100%; } }
            .bulding-list .wrapper .container .search-list-portfolio .choose-card .box:first-child {
              border-left: 5px solid #e1e1e1; }
              @media screen and (max-width: 991px) {
                .bulding-list .wrapper .container .search-list-portfolio .choose-card .box:first-child {
                  border-left: 0px; } }
            .bulding-list .wrapper .container .search-list-portfolio .choose-card .box:nth-of-type(1) .img-icon {
              background: url("../images/sell-icon.png"); }
            .bulding-list .wrapper .container .search-list-portfolio .choose-card .box:nth-of-type(1).active .img-icon {
              background: url("../images/sell-icon-active.png"); }
            .bulding-list .wrapper .container .search-list-portfolio .choose-card .box:nth-of-type(2) .img-icon {
              background: url("../images/rent-icon.png"); }
            .bulding-list .wrapper .container .search-list-portfolio .choose-card .box:nth-of-type(2).active .img-icon {
              background: url("../images/rent-icon-active.png"); }
            .bulding-list .wrapper .container .search-list-portfolio .choose-card .box:nth-of-type(3) .img-icon {
              background: url("../images/local-icon.png"); }
            .bulding-list .wrapper .container .search-list-portfolio .choose-card .box:nth-of-type(3).active .img-icon {
              background: url("../images/local-icon-active.png"); }
            .bulding-list .wrapper .container .search-list-portfolio .choose-card .box .img-icon {
              margin-left: 20px;
              margin-right: 20px;
              background-repeat: no-repeat !important;
              background-position: center;
              width: 26px;
              height: 25px; }
            .bulding-list .wrapper .container .search-list-portfolio .choose-card .box .title {
              color: #0e394b;
              font-weight: 500;
              font-size: 16px; }
            .bulding-list .wrapper .container .search-list-portfolio .choose-card .box.active {
              border-top: 5px solid #af1e23;
              border-bottom: 0px;
              height: 51px; }
              .bulding-list .wrapper .container .search-list-portfolio .choose-card .box.active .title {
                color: #af1e23; }
        .bulding-list .wrapper .container .search-list-portfolio .choose-card-section {
          width: 100%;
          padding-left: 15px;
          padding-right: 15px;
          padding-top: 80px;
          background: white;
          box-sizing: border-box;
          transition: 2s;
          animation-duration: 0.9s;
          animation-delay: -0.75s; }
          .bulding-list .wrapper .container .search-list-portfolio .choose-card-section .forms {
            position: relative;
            z-index: -999;
            display: none;
            flex-wrap: wrap; }
            .bulding-list .wrapper .container .search-list-portfolio .choose-card-section .forms:first-child {
              display: flex;
              opacity: 1;
              min-height: 71px;
              height: 100%;
              z-index: 1; }
            @media screen and (max-width: 991px) {
              .bulding-list .wrapper .container .search-list-portfolio .choose-card-section .forms {
                justify-content: center; } }
            .bulding-list .wrapper .container .search-list-portfolio .choose-card-section .forms .input-box {
              margin-right: 10px; }
              .bulding-list .wrapper .container .search-list-portfolio .choose-card-section .forms .input-box:first-child {
                max-width: 350px; }
                @media screen and (max-width: 1199px) {
                  .bulding-list .wrapper .container .search-list-portfolio .choose-card-section .forms .input-box:first-child {
                    max-width: 185px; } }
                @media screen and (max-width: 991px) {
                  .bulding-list .wrapper .container .search-list-portfolio .choose-card-section .forms .input-box:first-child {
                    max-width: 350px; } }
              @media screen and (max-width: 991px) {
                .bulding-list .wrapper .container .search-list-portfolio .choose-card-section .forms .input-box {
                  margin-bottom: 20px; } }
              .bulding-list .wrapper .container .search-list-portfolio .choose-card-section .forms .input-box:last-child {
                margin-right: 0px; }
              .bulding-list .wrapper .container .search-list-portfolio .choose-card-section .forms .input-box .text {
                color: #0e394b;
                font-size: 14px;
                font-weight: 500;
                text-transform: uppercase;
                padding-bottom: 10px; }
              .bulding-list .wrapper .container .search-list-portfolio .choose-card-section .forms .input-box .select-style {
                border: 1px solid #e1e1e1;
                height: 38px;
                display: flex;
                align-items: center;
                margin-right: 10px;
                width: 100%; }
                .bulding-list .wrapper .container .search-list-portfolio .choose-card-section .forms .input-box .select-style select {
                  padding-left: 15px;
                  padding-right: 15px;
                  font-weight: bold;
                  font-size: 14px;
                  color: #0e394b;
                  border: 0;
                  background: none;
                  position: relative;
                  z-index: 1;
                  max-width: 350px;
                  width: 100%;
                  height: 100%;
                  outline: none; }
              .bulding-list .wrapper .container .search-list-portfolio .choose-card-section .forms .input-box .from-to {
                display: flex; }
                .bulding-list .wrapper .container .search-list-portfolio .choose-card-section .forms .input-box .from-to input {
                  border: 1px solid #e1e1e1;
                  height: 40px;
                  padding-left: 15px;
                  padding-right: 15px;
                  font-weight: bold;
                  font-size: 14px;
                  color: #0e394b;
                  width: 90px;
                  box-sizing: border-box; }
                  .bulding-list .wrapper .container .search-list-portfolio .choose-card-section .forms .input-box .from-to input:first-child {
                    margin-right: 10px; }
            .bulding-list .wrapper .container .search-list-portfolio .choose-card-section .forms button {
              background: #0e394b;
              font-size: 14px;
              color: white;
              padding-left: 15px;
              padding-right: 15px;
              height: 40px;
              border: 0px;
              margin-top: 30px;
              cursor: pointer; }
  .bulding-list .content {
    padding-top: 50px; }
    @media screen and (max-width: 991px) {
      .bulding-list .content {
        padding-top: 280px; } }
    @media screen and (max-width: 767px) {
      .bulding-list .content {
        padding-top: 500px; } }
    @media screen and (max-width: 459px) {
      .bulding-list .content {
        padding-top: 700px; } }
    .bulding-list .content .title-page {
      color: #0e394b !important;
      font-size: 24px;
      font-weight: 300;
      display: flex;
      flex-direction: column;
      border-left: 3px solid #af1e23;
      padding-left: 10px;
      padding-top: 5px;
      padding-bottom: 5px; }
    .bulding-list .content .text-content {
      color: #0e394b;
      font-size: 14px;
      font-weight: 500;
      padding-top: 10px; }
  .bulding-list .realisations {
    padding-top: 50px;
    overflow-x: auto; }
    .bulding-list .realisations .title-page {
      color: #0e394b !important;
      font-size: 24px;
      font-weight: 300;
      display: flex;
      flex-direction: column;
      border-left: 3px solid #af1e23;
      padding-left: 10px;
      padding-top: 5px;
      padding-bottom: 5px; }
    .bulding-list .realisations .apartments_list {
      width: 100%;
      border-collapse: collapse;
      padding-top: 80px;
      padding-bottom: 50px;
      padding-left: 5px;
      padding-right: 5px;
      box-sizing: border-box; }
      .bulding-list .realisations .apartments_list.rent tr {
        cursor: pointer; }
      .bulding-list .realisations .apartments_list th:hover:after {
        content: " ";
        background: url("../images/arrows.png") no-repeat center;
        display: inline-block;
        position: absolute;
        width: 25px;
        height: 25px; }
      .bulding-list .realisations .apartments_list thead {
        width: 100%; }
        .bulding-list .realisations .apartments_list thead tr {
          border-bottom: 1px solid #0e394b; }
          .bulding-list .realisations .apartments_list thead tr th {
            color: #8a8a8a;
            font-size: 14px;
            font-weight: 500;
            padding: 20px;
            background-color: white;
            cursor: pointer; }
      .bulding-list .realisations .apartments_list tbody {
        border-collapse: collapse; }
        .bulding-list .realisations .apartments_list tbody tr {
          border-bottom: 1px solid #d7d7d7; }
          .bulding-list .realisations .apartments_list tbody tr:last-child {
            bordeR: 0px solid black; }
          .bulding-list .realisations .apartments_list tbody tr td {
            text-align: center;
            font-size: 14px;
            font-weight: 500;
            color: #0e394b;
            padding-top: 10px;
            padding-bottom: 10px; }
            .bulding-list .realisations .apartments_list tbody tr td h3 {
              font-size: 14px;
              font-weight: 500;
              margin: 0; }
          .bulding-list .realisations .apartments_list tbody tr .free {
            color: #2cab00; }
          .bulding-list .realisations .apartments_list tbody tr .rez {
            color: #f38010; }
          .bulding-list .realisations .apartments_list tbody tr .sold {
            color: #af1e23; }
          .bulding-list .realisations .apartments_list tbody tr .apartment_finished {
            color: #af1e23; }
          .bulding-list .realisations .apartments_list tbody tr .apartment_finished.active {
            color: #2cab00; }
    .bulding-list .realisations .btn {
      padding-left: 20px;
      padding-right: 20px;
      color: white;
      font-size: 16px;
      background: #0e394b;
      display: flex;
      justify-content: center;
      align-items: center;
      width: fit-content;
      margin-left: auto;
      margin-right: auto;
      height: 40px;
      text-decoration: none;
      margin-top: 50px; }
  .bulding-list .container .title-page {
    color: #0e394b !important;
    font-size: 24px;
    font-weight: 300;
    display: flex;
    flex-direction: column;
    border-left: 3px solid #af1e23;
    padding-left: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    margin: 0; }
  .bulding-list .container .gallery-section {
    display: flex;
    flex-wrap: wrap;
    padding-top: 50px; }
    .bulding-list .container .gallery-section a {
      max-width: 360px;
      width: 100%;
      height: auto;
      position: relative;
      transition: 5s;
      margin: 5px; }
      .bulding-list .container .gallery-section a:hover:before {
        max-width: 360px;
        width: 100%;
        height: 250px;
        background: rgba(174, 30, 34, 0.6);
        position: absolute;
        content: ''; }
      .bulding-list .container .gallery-section a:hover:after {
        position: absolute;
        content: '';
        width: 26px;
        height: 27px;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        top: 48%;
        background: url("../images/icon-loop.png") no-repeat center; }
      .bulding-list .container .gallery-section a img {
        max-width: 360px;
        width: 100%;
        height: 250px;
        object-fit: cover; }

.svg-subpage {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-top: 80px;
  padding-bottom: 50px;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box; }
  .svg-subpage .container {
    display: flex;
    align-items: center;
    flex-direction: column; }
    .svg-subpage .container .title {
      color: #0e394b;
      font-size: 30px;
      text-transform: uppercase;
      font-weight: 500; }
    .svg-subpage .container .text {
      color: #0e394b;
      font-size: 24px;
      font-weight: 300;
      padding-top: 20px; }
    .svg-subpage .container .information {
      display: flex;
      justify-content: space-between;
      max-width: 680px;
      width: 100%;
      padding-top: 40px; }
      .svg-subpage .container .information .box {
        display: flex;
        align-items: center; }
        .svg-subpage .container .information .box .color {
          background: #c3df81;
          height: 25px;
          width: 25px;
          margin-right: 10px; }
        .svg-subpage .container .information .box .box-text {
          color: #0e394b;
          font-size: 14px;
          font-weight: 500; }
    .svg-subpage .container .btn {
      background: #0e394b;
      padding-left: 20px;
      padding-right: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 45px;
      color: white;
      font-size: 18px;
      text-decoration: none;
      margin-top: 40px; }
  .svg-subpage .svg-img {
    margin-top: 50px; }

/* Slider */
.slick-loading .slick-list {
  background: #fff url("./ajax-loader.gif") center center no-repeat; }

/* Icons */
/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none; }
  .slick-prev:hover, .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    outline: none;
    background: transparent;
    color: transparent; }
    .slick-prev:hover:before, .slick-prev:focus:before,
    .slick-next:hover:before,
    .slick-next:focus:before {
      opacity: 1; }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.25; }
  .slick-prev:before,
  .slick-next:before {
    font-family: "slick";
    font-size: 20px;
    line-height: 1;
    color: white;
    opacity: 0.75;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px; }
  [dir="rtl"] .slick-prev {
    left: auto;
    right: -25px; }
  .slick-prev:before {
    content: "←"; }
    [dir="rtl"] .slick-prev:before {
      content: "→"; }

.slick-next {
  right: -25px; }
  [dir="rtl"] .slick-next {
    left: -25px;
    right: auto; }
  .slick-next:before {
    content: "→"; }
    [dir="rtl"] .slick-next:before {
      content: "←"; }

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px; }

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer; }
    .slick-dots li button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer; }
      .slick-dots li button:hover, .slick-dots li button:focus {
        outline: none; }
        .slick-dots li button:hover:before, .slick-dots li button:focus:before {
          opacity: 1; }
      .slick-dots li button:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "•";
        width: 20px;
        height: 20px;
        font-family: "slick";
        font-size: 6px;
        line-height: 20px;
        text-align: center;
        color: black;
        opacity: 0.25;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
    .slick-dots li.slick-active button:before {
      color: black;
      opacity: 0.75; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

html.lb-disable-scrolling {
  overflow: hidden;
  /* Position fixed required for iOS. Just putting overflow: hidden; on the body is not enough. */
  position: fixed;
  height: 100vh;
  width: 100vw; }

.lightboxOverlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  background-color: black;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  opacity: 0.8;
  display: none; }

.lightbox {
  position: absolute;
  left: 0;
  width: 100%;
  z-index: 10000;
  text-align: center;
  line-height: 0;
  font-weight: normal; }

.lightbox .lb-image {
  display: block;
  height: auto;
  max-width: inherit;
  max-height: none;
  border-radius: 3px;
  /* Image border */
  border: 4px solid white; }

.lightbox a img {
  border: none; }

.lb-outerContainer {
  position: relative;
  *zoom: 1;
  width: 250px;
  height: 250px;
  margin: 0 auto;
  border-radius: 4px;
  /* Background color behind image.
       This is visible during transitions. */
  background-color: white; }

.lb-outerContainer:after {
  content: "";
  display: table;
  clear: both; }

.lb-loader {
  position: absolute;
  top: 43%;
  left: 0;
  height: 25%;
  width: 100%;
  text-align: center;
  line-height: 0; }

.lb-cancel {
  display: block;
  width: 32px;
  height: 32px;
  margin: 0 auto;
  background: url(../images/loading.gif) no-repeat; }

.lb-nav {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10; }

.lb-container > .nav {
  left: 0; }

.lb-nav a {
  outline: none;
  background-image: url("data:image/gif;base64,R0lGODlhAQABAPAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=="); }

.lb-prev, .lb-next {
  height: 100%;
  cursor: pointer;
  display: block; }

.lb-nav a.lb-prev {
  width: 34%;
  left: 0;
  float: left;
  background: url(../images/prev.png) left 48% no-repeat;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  -webkit-transition: opacity 0.6s;
  -moz-transition: opacity 0.6s;
  -o-transition: opacity 0.6s;
  transition: opacity 0.6s; }

.lb-nav a.lb-prev:hover {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1; }

.lb-nav a.lb-next {
  width: 64%;
  right: 0;
  float: right;
  background: url(../images/next.png) right 48% no-repeat;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  -webkit-transition: opacity 0.6s;
  -moz-transition: opacity 0.6s;
  -o-transition: opacity 0.6s;
  transition: opacity 0.6s; }

.lb-nav a.lb-next:hover {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1; }

.lb-dataContainer {
  margin: 0 auto;
  padding-top: 5px;
  *zoom: 1;
  width: 100%;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px; }

.lb-dataContainer:after {
  content: "";
  display: table;
  clear: both; }

.lb-data {
  padding: 0 4px;
  color: #ccc; }

.lb-data .lb-details {
  width: 85%;
  float: left;
  text-align: left;
  line-height: 1.1em; }

.lb-data .lb-caption {
  font-size: 13px;
  font-weight: bold;
  line-height: 1em; }

.lb-data .lb-caption a {
  color: #4ae; }

.lb-data .lb-number {
  display: block;
  clear: left;
  padding-bottom: 1em;
  font-size: 12px;
  color: #999999; }

.lb-data .lb-close {
  display: block;
  float: right;
  width: 30px;
  height: 30px;
  background: url(../images/close.png) top right no-repeat;
  text-align: right;
  outline: none;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
  opacity: 0.7;
  -webkit-transition: opacity 0.2s;
  -moz-transition: opacity 0.2s;
  -o-transition: opacity 0.2s;
  transition: opacity 0.2s; }

.lb-data .lb-close:hover {
  cursor: pointer;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1; }

@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translatex(-10px);
    -moz-transform: translatex(-10px);
    -o-transform: translatex(-10px);
    transform: translatex(-10px); }
  to {
    opacity: 1;
    -webkit-transform: translatex(0);
    -moz-transform: translatex(0);
    -o-transform: translatex(0);
    transform: translatex(0); } }

@-moz-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translatex(-10px);
    -moz-transform: translatex(-10px);
    -o-transform: translatex(-10px);
    transform: translatex(-10px); }
  to {
    opacity: 1;
    -webkit-transform: translatex(0);
    -moz-transform: translatex(0);
    -o-transform: translatex(0);
    transform: translatex(0); } }

@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translatex(-100px);
    -moz-transform: translatex(-100px);
    -o-transform: translatex(-100px);
    transform: translatex(-100px); }
  to {
    opacity: 1;
    -webkit-transform: translatex(0);
    -moz-transform: translatex(0);
    -o-transform: translatex(0);
    transform: translatex(0); } }

.in-left {
  -webkit-animation-name: fadeInLeft;
  -moz-animation-name: fadeInLeft;
  -o-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-duration: 0.2s;
  -moz-animation-duration: 0.2s;
  -o-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-delay: 0.2s;
  -moz-animation-delay: 0.2s;
  -o-animation-duration: 0.2s;
  animation-delay: 0.2s; }

.floor {
  text-align: center;
  color: #0e394b; }
  .floor .title {
    font-size: 30px;
    font-weight: 400;
    margin: 30px 0 0px; }
  .floor .subtitle {
    font-size: 24px;
    font-weight: 100; }
  .floor .legend {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin: 20px 0 30px; }
    .floor .legend .legend-item {
      position: relative;
      font-size: 14px;
      font-weight: 600;
      margin: 0 30px;
      padding-left: 33px; }
      .floor .legend .legend-item:before {
        content: '';
        position: absolute;
        width: 23px;
        height: 23px;
        left: 0;
        top: 0; }
      .floor .legend .legend-item.free:before {
        background-color: #c3df81; }
      .floor .legend .legend-item.rent:before {
        background-color: #f1ec94; }
      .floor .legend .legend-item.sold:before {
        background-color: #f6b99a; }
  .floor .btn {
    padding-left: 20px;
    padding-right: 20px;
    color: white;
    font-size: 16px;
    background: #0e394b;
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    height: 40px;
    text-decoration: none;
    margin-top: 0px; }
