.gallery {
    padding-top:50px;
    padding-bottom:50px;
    background:white;
    .title {
        color: #0e394b;
        font-size: 24px;
        font-weight: 300;
        display: flex;
        flex-direction: column;
        border-left: 5px solid #af1e23;
        padding-left: 10px;
        padding-bottom:10px;
        padding-top:10px;
		margin:0;
    }
    .wrapper {
        display:flex;
        padding-top:50px;
        @media screen and (max-width:991px) {
            flex-direction: column;
            flex-flow:row wrap;
            
        }
        .left {
            max-width:750px;
            width:100%;
            @media screen and (max-width:991px) {
                order:2;
            }
            .description {
                width:100%;
                padding-bottom:40px;
                .date {
                    color:#af1e23;
                    font-size:14px;
                    font-weight:500;
                }
                .title-description {
                    color:#0e394b;
                    font-size:24px;
                    font-weight:300;
                    padding-bottom:30px;
                }
                .text {
                    font-size:14px;
                    font-weight:500;
                    color:#0e394b;
                }
            }
        }
        .right {
            max-width:290px;
            width:100%;
            list-style: none;
            display:flex;
            flex-direction: column;
            @media screen and (max-width:991px) {
                order:1;
                max-width:900px;
                flex-direction: initial;
                flex-wrap:wrap;
            } 
            li {
                width:100%;
                background:#0e394b;
                padding-left:30px;
                box-sizing: border-box;
                border-bottom:1px solid white;
				&.active, &:hover{
					background:#3a7a94;
				}
                @media screen and (max-width:991px) {
                    background-size:100px 100px;
                    max-width:300px;
                    background-repeat:no-repeat;
                    background-size: cover;
                }
                a {
                    color:white;
                    display:flex;
                    align-items: center;
                    padding-top:25px;
                    padding-bottom:25px;
                    text-decoration:none;
                    font-size:18px;
                    font-weight:300;
                    
                }
                ul {
                    background:#0f627e;
                    box-sizing: border-box;
                    padding-left:10px;
                    margin-left:-30px;
                    list-style-position: outside;
                    list-style: none;
                    overflow-y: hidden;
                    max-height: 0px;     
                    padding-top:0px;
                    padding-bottom:0px;      
                    transition-property: all;
                    transition-duration: 0.5s;
                    transition-timing-function: cubic-bezier(0.5, 0.5, 0.5, 0.5);
                    li {
                        background:#0f627e;
                        border:none;
                        position:relative;
                        &:before {
                            position:absolute;
                            content:'';
                            background:#65c2e1;
                            height:5px;
                            width:5px;
                            border-radius:20px;
                            left:10px;
                            top:10px;
                        } 
                        a {
                            color:#65c2e1;
                            font-size:15px;
                            font-weight:300;
                            padding-top:2px;
                            padding-bottom:2px;
                            &:hover {
                                color:white;
                            }
                        }
                    }
                }
                &.active {
                    ul {
                        padding-top:25px;
                        padding-bottom:25px;
                        max-height: 500px;  

                    }
                }
            }
        }
    }
	.gallery-section {
		display:flex;
		flex-wrap:wrap;
		padding-top:50px;
		a {
			max-width:360px;
			width:100%;
			height:auto;
			position:relative;
			transition: 5s;
			margin:7px;
			&:hover:before {
				max-width:360px;
				width:100%;
				height:250px;
				background:rgba(174,30,34,0.6);
				position:absolute;
				content:'';
			}
			&:hover:after {
				position:absolute;
				content:'';
				width:26px;
				height:27px;
				left:0;
				right:0;
				margin-left:auto;
				margin-right:auto;
				top:48%;
				background:url('../images/icon-loop.png') no-repeat center;
			}
			img {
				max-width:360px;
				width:100%;
				height:250px;
				object-fit:cover;
			}
		}
	}
}