.textpage {
    padding-top:50px;
    padding-bottom:50px;
    background:white;
    .title {
        color: #0e394b;
        font-size: 24px;
        font-weight: 300;
        display: flex;
        flex-direction: column;
        border-left: 5px solid #af1e23;
        padding-left: 10px;
        padding-bottom:10px;
        padding-top:10px;
    }
    .text {
        color:#0e394b;
        font-size:14px;
        font-weight:500;
    }
}