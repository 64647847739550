footer {
    border-top:1px solid #e9edef;
    padding-top:85px;
    .container {
        display:flex;
        justify-content: space-between;
        flex-wrap:wrap;
        .title {
            color: $color-dark;
            font-size: 36px;
            font-weight: 300;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            flex-direction: column;
            border-left: 5px solid #af1e23;
            padding-left: 10px;
            height:100px;
			h2{
				font-size: 36px;
				font-weight: 300;
				margin:0;
			}
            span {
                font-size: 17px;
            }
            @media screen and (max-width:767px) {
                width:100%;
                
            }
        }      
        .contact-info {
            display:flex;
            flex-wrap:wrap;
            justify-content: center;
            align-items: center;
            width:55%;
            @media screen and (max-width:767px) {
                width:100%;
                margin-top:50px;
            }
            .box {
                width:50%;
                display:flex;
                align-items:center;
                margin-bottom:30px;
                @media screen and (max-width:500px) {
                    width:100%;
                }
                .img {
                    height:50px;
                    width:50px;
                    display:flex;
                    justify-content: center;
                    align-items: center;
					align-self:flex-start;
                }
                .text {
                    font-size:16px;
                    font-weight:300;
                    color:$color-dark;
                    margin-left:10px;
                    width:100%;
                }
            }
        } 
    }
    .map-and-form {
        width:100%;
        display:flex;
        flex-wrap:wrap;
        padding-top:50px;
        border-bottom:1px solid #e9edef;
        .google-maps {
            width:50%;
            height:375px;
            text-align:center;
            display:flex;
            justify-content: center;
            align-items: center;
            background:$color-dark;
            color:white;
            @media screen and (max-width:991px) {
                width:100%;
                margin-bottom:70px;
            }
        }
        .form-box {
            width:50%;
            @media screen and (max-width:991px) {
                width:80%;
                margin-left: auto;
                margin-right:auto;
            }
            form {
                width:100%;
                max-width:450px;
                padding-left:130px;
                @media screen and (max-width:1199px) {
                    padding-left:50px;
                }
                @media screen and (max-width:991px) {
                    max-width:991px;
                    padding-left:0px;
                }
                input {
                    width:100%;
                    border:1px solid #e9edef;
                    height:43px;
                    margin-bottom:10px;
                    font-weight:300;
                    font-size:14px;
                    color:#0e394b;
                    padding-left:15px;
                    box-sizing: border-box;
                    &::placeholder {
                        color:#0e394b;
                    }
                }
                textarea {
                    width:100%;
                    border:1px solid #e9edef;
                    height:190px;
                    margin-bottom:10px;
                    font-weight:300;
                    font-size:14px;
                    color:#0e394b;
                    padding-left:15px;
                    box-sizing: border-box; 
                    font-family: 'Poppins', sans-serif;
					&::placeholder{
						color:#0e394b;
					}
                }
                button {
                    background:$color-dark;
                    height:45px;
                    justify-content: center;
                    align-items: center;
                    color:white;
                    font-size:14px;
                    padding-left:25px;
                    padding-right:25px;
                    border:0px;
                    font-weight:500;
					cursor:pointer;
                }
            }
        }
    }
    .footer-container-menu {
        padding-top:50px;
        align-items: center;
		.main-menu{
			a.active {
				padding-bottom:34px;
			}
			a:hover {
				padding-bottom:34px;
			}
		}
    }
    .created-by {
        border-top:1px solid #e9edef;
        .container.by {
            justify-content: space-between;
            align-items: center;
            height:60px;
            div {
                color:#6c6b6b;
                font-weight:300;
                font-size:12px;
                a {
                    color:#af1e23;
                    font-weight:500;
                    text-decoration: none;
                }
            }
        }
    }
}