.svg-subpage {
    width:100%;
    display:flex;
    justify-content: center;
    flex-direction: column;
    padding-top:80px;
    padding-bottom:50px;
    padding-left:20px;
    padding-right:20px;
    box-sizing: border-box;
    .container {
        display:flex;
        align-items: center;
        flex-direction: column;
        .title {
            color:#0e394b;
            font-size:30px;
            text-transform: uppercase;
            font-weight:500;
        }
        .text {
            color:#0e394b;
            font-size:24px;
            font-weight:300;  
            padding-top:20px; 
        }
        .information {
            display:flex;
            justify-content: space-between;
            max-width: 680px;
            width:100%;
            padding-top:40px;
            .box {
                display:flex;
                align-items: center;
                .color {
                    background:#c3df81;
                    height:25px;
                    width:25px;
                    margin-right:10px;
                }
                .box-text {
                    color:#0e394b;
                    font-size:14px;
                    font-weight:500;
                }
            }
        }
        .btn {
            background:#0e394b;
            padding-left:20px;
            padding-right:20px;
            display:flex;
            justify-content: center;
            align-items: center;
            height:45px;
            color:white;
            font-size:18px;
            text-decoration: none;
            margin-top:40px;
        }
    }
    .svg-img {
        margin-top:50px;
    }
}