.realisations {
    padding-top:50px;
    padding-bottom:50px;
    background:white;

    .title {
        color: #0e394b;
        font-size: 24px;
        font-weight: 300;
        display: flex;
        flex-direction: column;
        border-left: 5px solid #af1e23;
        padding-left: 10px;
        padding-bottom:10px;
        padding-top:10px;
		margin:0;
    }
    .text {
        color:#0e394b;
        font-weight:500;
        font-size:14px;
        padding-bottom:40px;
        padding-top:40px;
    }
    .realizations_list {
        display:flex;
        flex-wrap:wrap;
        justify-content: center;
        .realization {
            text-decoration:none;
            padding-right:15px;
            margin-bottom:45px;
            max-width:360px;
            width:100%;
            position:relative;
            &:before {
                max-width:360px;
                width:100%;
                height:250px;
                transition: 1s;
                position:absolute;
                content:'';
            }
            &:hover:before {
                background:rgba(175,30,35,0.35);
            }
            &:after {
                width:26px;
                height:27px;
                position:absolute;
                content:'';
                top:115px;
                left:0;
                right:0;
                margin:auto;
                opacity:0;
                transition: 2s;
                background:url('../images/icon-loop.png') no-repeat center;
            }
            &:hover:after {
                opacity:1.0;
            }
            &:nth-of-type(3n+3) {
                padding-right:0px;
                @media screen and (max-width:1199px) {
                    padding-right:15px;
                }
            }
            @media screen and (max-width:1199px) {
                &:nth-of-type(2n+2) {
                    padding-right:0px;
                }
            }
            @media screen and (max-width:991px) {
                padding-right:0px;
            }
            img {
                max-width:360px;
                width:100%;
                height:250px;;
            }
            .realization_details {
                padding-top:25px;
                position:relative;
                &:before {
                    position: absolute;
                    content: '';
                    width: 10px;
                    height: 20px;
                    background: url(../images/portfolio-arrow.png) no-repeat center;
                    bottom: 50px;
                    right: 15px;
                    
                }
                .realization_name {
                    font-size:18px;
                    color:#0e394b;
                    font-weight:300;
					margin:0;
                }
                .realization_address {
                    font-size:18px;
                    color:#0e394b;
                    font-weight:500;
                }
                .realization_year {
                    font-size:14px;
                    color:#af1e23;
                    font-weight:300;
                }
            }
        }
    }
}