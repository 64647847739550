header {
    display:flex;
    justify-content: space-between;
    align-items: center;
    height:105px;
    padding-top:10px;
    padding-bottom:10px;
    .logo {
        width:68px;
        height:85px;
        display:block;
    }
    .menu-btn {
        display:none;
        cursor:pointer;
        span {
            width:40px;
            height:6px;
            background:#0e394b;
            margin-bottom:6px;
            display:block;
            &:last-child {
                margin-bottom:0px;
            }
        }
        @media screen and (max-width:768px) {
            display:block;
        }
    }
}

