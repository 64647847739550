.contact {
    padding-top:70px;
    padding-bottom:50px;
    .title {
        color: #0e394b;
        font-size: 24px;
        font-weight: 300;
        display: flex;
        flex-direction: column;
        border-left: 5px solid #af1e23;
        padding-left: 10px;
        padding-bottom:10px;
        padding-top:10px;
    }
    .wrapper {
        display:flex;
        flex-wrap:wrap;
        border-bottom:1px solid #d6dee1;
        padding-bottom:50px;
        &:last-child {
            border-bottom:none;
        }
        .left {
            width:50%;
            @media screen and (max-width:991px) {
                width:100%;
            }
            &.no-map {
                width:100%;
                display:flex;
                padding-top:30px;
                padding-bottom:30px;
                @media screen and (max-width:991px) {
                    width:100%;
                    flex-wrap:wrap;
                }
                .information {
                    max-width:300px;
                    width:100%;
                    border:none;
                    margin-bottom:40px;
                    @media screen and (max-width:991px) {
                        max-width:1000px;
                        width:50%;
                        padding:0px;
                        justify-content: center;
                        @media screen and (max-width:767px) {
                            width:100%;
                        }
                        .box {
                            width:80%;
                            @media screen and (max-width:767px) {
                                width:100%;
                            }
                            &.tel {
                                span {
                                    margin-left:37px;
                                    @media screen and (max-width:767px) {
                                        &:nth-of-type(2) {
                                            margin-left:-23px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .text-box {
                        color:#0e394b;
                        font-size:15px;
                        font-weight:bold;
                        max-width:300px;
                        width:100%;
						min-height:70px;
						margin:0;
                    }
                }
            }
            .text {
                color:#0e394b;
                font-size:15px;
                font-weight:bold;
                max-width:300px;
                width:100%;
                padding-bottom:40px;
                padding-top:40px;
				margin:0;
            }
            .information {
                max-width:490px;
                width:100%;
                display:flex;
                flex-wrap:wrap;
                justify-content: space-between;
                padding-bottom:20px;
                padding-right:65px;
                border-bottom:1px solid #d6dee1;
                @media screen and (max-width:991px) {
                    max-width:1000px;

                }
                @media screen and (max-width:767px) {
                    flex-direction: column;
                }
                &:last-child {
                    border-bottom:none;
                }
                .box {
                    max-width:195px;
                    width:100%;
                    padding-bottom:3px;
                    @media screen and (max-width:991px) {
                        max-width:991px;
                        width:50%;
                    }
                    span {
                        font-weight:bold;
                        margin-right:10px;
                    }
                    color:#0e394b;
                    font-weight:300;
                    font-size:15px;
                    &.tel {
                        display:flex;
                        flex-wrap:wrap;
                        span {
                            width:100%;
                            font-weight:300;
                            margin-left:30px;
                            &:nth-of-type(1) {
                                width:10%;
                                font-weight:bold;
                                margin-left:0;
                            }
                            &:nth-of-type(2) {
                                width:75%;
                                margin-left:0;
                            }
                        }
                    }
                }
            }
        }
        .right {
            width:50%;
            padding-left:30px;
            box-sizing: border-box;
            display:flex;
            align-items: center;
            @media screen and (max-width:991px) {
                width:100%;
                padding-left:0px;
            }
            .google-maps {
                width: 100%;
                height: 400px;
                text-align: center;
                display: flex;
                justify-content: center;
                align-items: center;
                background: #0e394b;
                color: white;
            }
        }
    }
}