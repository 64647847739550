$color-dark:#0e394b;
$color-red:#af1e23;

// menu 
.main-menu {
    list-style:none;
    display:flex;
    position:relative;
    &.no-rwd {
        @media screen and (max-width:769px) {
            display:none;
        }
    }
    li {
        margin-right:35px;
        &:last-child {
            margin-right:0px;
        }
        a {
            text-decoration:none;
            font-weight:500;
            text-transform: uppercase;
            color:$color-dark;
            padding-bottom:39px;
            border-bottom:2px solid none;
            transition: all 0.3s;
            &:hover {
                color:$color-red;
                border-bottom:2px solid $color-red;
            }
            &.active {
                color:$color-red;
                border-bottom:2px solid $color-red;
            }
            &.click {
                border:none;
            }
        }
        ul {
            list-style:none;
            top:63px;
            margin-left:-15px;
            position:absolute;
            z-index:1;
            padding-left:15px;
            padding-right:15px;
            background:white;
            width:fit-content;
            display:none;
            .active {
                display:block;
            }
            li {
                padding-bottom:10px;
                a {
                    color:$color-dark;
                    transition:all 0.5s;
                    font-weight:500;
                    font-size:14px;
                    &:hover {
                        color:$color-red;
                        border:none;
                    }
                }
            }
        }
    }
    &.rwd {
        display:none;
        @media screen and (max-width:768px) {
            position:fixed;
            z-index:5;
            left:0;
            top:-16px;
            flex-direction: column;
            background:$color-dark;
            height:100%;
            max-width:250px;
            padding-right:15px;
            width:100%;

            li {
                margin-right:10px;
                border-bottom:1px solid rgba( 255, 255, 255, 0.1 );
                a {
                    color:white;
                    display:block;
                    padding-bottom:20px;
                    padding-top:20px;
                    &.active {
                        border:none;
                    }
                    &:hover {
                        border:none;
                    }
                }
                ul {
                    position:relative;
                    top:0;
                    background:none;
                    li {
                        margin-left:30px;
                        border-bottom:0px solid rgba( 255, 255, 255, 0.1 );
                        a {
                            color:white;
                            font-weight:300;
                        }
                    }
                }
            }
        }
    }
}

#mainSlider {
    height:632px;
    .slider-section {
        height:632px;
        background-position:center !important;
        background-repeat:no-repeat !important;
        .container  {
            top:200px;
            width:fit-content;
            background:rgba(36,69,82,0.63);
            display:flex;
            padding:25px;
            .slider-navigation {
                width:35px;
                padding-right:20px;
                margin-right:20px;
                border-right:3px solid white;
                display:flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                .arrow-section {
                    display:flex;
                    justify-content: space-between;
                    .prev-slide {
                        width:5px;
                        height:9px;
                        background:url('../images/arrow-prev.png') center no-repeat !important;
                        cursor:pointer; 
                        padding:10px;
                    }
                    .next-slide {
                        width:5px;
                        height:9px;
                        background:url('../images/arrow-next.png') center no-repeat !important; 
                        cursor:pointer;
                        padding:10px;
                    }
                }
                .loader-slider {
                    animation: spin 4.5s linear infinite;
                }
                .slider-number {
                    color:white;
                    font-size:15px;
                    text-align: center;
                    margin-top:10px;
                    margin-bottom:4px;
                    font-weight:300;
                }   
            }
            .content {
                max-width:710px;
                width:100%;
                display:flex;
                flex-wrap:wrap;
                align-items: center;
                justify-content: space-between;
                line-height:1.2;
                .title {
                    font-size:50px;
                    font-weight:300;
                    color:white;
                    text-transform: uppercase;
                    width:100%;
                    margin:0;
                    text-shadow:2px 2px black;
                    @media screen and (max-width:991px) {
                        font-size:40px;
                    }
                    @media screen and (max-width:767px) {
                        font-size:25px;
                    }
                    @media screen and (max-width:504px) {
                        font-size:20px;
                    }
                }
                .text {
                    font-size:40px;
                    color:white;
                    font-weight:500;
                    text-transform:uppercase;
                    text-shadow:2px 2px black;
                    @media screen and (max-width:991px) {
                        font-size:35px;
                    }
                    @media screen and (max-width:767px) {
                        font-size:20px;
                        padding-bottom:20px;
                    }
                    @media screen and (max-width:504px) {
                        font-size:18px;
                    }
                }
                .button {
                    font-size:14px;
                    padding-left:15px;
                    padding-right:15px;
                    border:1px solid white;
                    display:flex;
                    align-items: center;
                    height:40px;
                    color:white;
                    font-weight:300;
                    cursor:pointer;
                    text-decoration:none;
                }
            }
        }
    }
}

.wrapper-one {
    background:#032939;
    width:100%;
    min-height:470px;
    height:100%;
    position:relative;
    @media screen and (max-width:767px) {
        min-height:435px;
        height:435px;
    }  
    &:before {
        position:absolute;
        content:'';
        background:url('../images/wrapper-one-background.png') no-repeat center;
        max-width:1153px;
        width:100%;
        min-height:470px;
        height:100%;
        left:0;
        right:0;
        margin-left:auto;
        margin-right:auto;
        transition: 10s;
        z-index:1;
        @media screen and (max-width:767px) {
            min-height:435px;
            height:435px;
        }      
    }
    .container {
        display:flex;
        position:absolute;
        left:0;
        right:0;
        top:-72.5px;
        @media screen and (max-width:767px) {
            flex-direction: column;
            position:relative;
            top:0;
            justify-content: center;

        }
        @media screen and (max-width:767px) {
            padding:0;
        }
        .box {
            max-width:390px;
            width:100%;
            background:#0e394b;
            height:145px;
            display:flex;
            align-items: center;
            transition: 4s;
            transition-delay: 4s;
            position:relative;
            cursor:pointer;
            text-decoration: none;
            z-index:2;
            @media screen and (max-width:767px) {
                max-width:767px;
            }
            &:nth-of-type(1):after {
                background:url('../images/wynajem.png') no-repeat;
            }
            &:nth-of-type(2):after {
                background:url('../images/sprzedaz.png') no-repeat;
            }
            &:nth-of-type(3):after {
                background:url('../images/lokalebiurowe.png') no-repeat;
            }
            &:nth-of-type(2) {
                background:#af1e23;
            }
            &:after {
                position:absolute;
                content:'';
                transition: 1s;
                position:absolute;
                content:'';
                max-width:390px;
                width:100%;
                height:145px;
                opacity:0;
            }
            &:hover:after {
                opacity:1;
            }
            &:before {
                transition: 1s;
                position:absolute;
                content:'';
                max-width:390px;
                width:100%;
                height:145px;
                z-index:1;
            }

            &:hover:before {
                background:rgba(175,30,35,0.35);
            }
            .text {
                padding-left:30px;
                color:white;
                font-size:30px;
                font-weight:500;
                text-transform: uppercase;
                display:flex;
                flex-direction: column;
                position:relative;
                z-index:2;
				margin:0;
                span {
                    font-size:24px;
                    font-weight:300;
                }
            }
        }
    }
}

.wrapper-second {
    background:white;
    min-height:370px;
    height:100%;
    position:relative;
    z-index:2;
    .container {
        position:absolute;
        top:-300px;
        left:0;
        right:0;
        @media screen and (max-width:991px) {
            position:relative;
            top:0;
            margin-top:40px;
        }
        .our-portfolio {
            widows: 100%;
            display:flex;
            align-items:center;
            justify-content: space-between;
            position:relative;
            z-index:2;
            @media screen and (max-width:500px) {
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                padding-left:45px;
            }
            .text {
                color:white;
                font-size:36px;
                font-weight:300;
                display:flex;
                flex-direction: column;
                border-left:5px solid #af1e23;
                padding-left:10px;
				h2{
					font-size:36px;
					font-weight:300;
					margin:0;
				}
                @media screen and (max-width:991px) {
                    color:$color-dark;
                }
                span {
                    font-size:17px;
                }
            }
            .btn-more {
                font-size:14px;
                font-weight:500;
                color:white;
                text-decoration: none;
                display:flex;
                align-items: center;
                @media screen and (max-width:991px) {
                    color:$color-dark;
                }
                @media screen and (max-width:500px) {
                    padding-left:15px;
                    padding-top:20px;
                }
                img {
                    margin-left:10px;
                }
            }
        }
        .portfolio-list {
            display:flex;
            width:100%;
            margin-top:80px;
            position:relative;
            z-index:2;
            @media screen and (max-width:991px) {
                flex-direction: column;
            }
            .box {
                max-width:390px;
                width:100%;
                text-decoration: none;
                position:relative;
                margin:0 auto;
                padding-bottom:30px;
                &:before {
                    position:absolute;
                    content:'';
                    width:10px;
                    height:20px;
                    background:url('../images/portfolio-arrow.png') no-repeat center;
                    bottom:50px;
                    right:15px;
                }
                .picture-box {
					background-color:#afafaf !important;
					background-size:cover !important;
                    background-position:center !important;
                    background-repeat:no-repeat !important;
                    max-width:390px;
                    width:100%;
                    height:220px;
                    position:relative;
                    &:before {
                        max-width:390px;
                        width:100%;
                        height:220px;
                        transition: 1s;
                        position:absolute;
                        content:'';
                    }
                    &:hover:before {
                        background:rgba(175,30,35,0.35);
                    }
                    &:after {
                        width:26px;
                        height:27px;
                        position:absolute;
                        content:'';
                        background:url('../images/icon-loop.png') no-repeat center;
                        left:0;
                        right:0;
                        top:0;
                        bottom:0;
                        margin:auto;
                        opacity:0;
                        transition: 2s;
                    }
                    &:hover:after {
                        opacity:1.0;
                    }
                    &:hover {
                        max-width:390px;
                        width:100%;
                        height:220px;
                    }
                }
                .title {
                    font-weight:300;
                    font-size:18px;
                    color:#0e394b;
                    text-transform: uppercase;
                    padding-left:15px;
                    margin:30px 0 0;
                }
                .address {
                    font-weight:500;
                    font-size:18px;
                    color:#0e394b;
                    padding-left:15px;
                }
            }
        }
    }
}

.wrapper-third {
    background:#0e394b;
    width:100%;
    position:relative;
    z-index:3;
    padding-bottom:70px;
    @media screen and (max-width:991px) {
        padding-top:80px;
        padding-bottom:80px;
    }
    .container {
        .search-list-portfolio {
            position:absolute;
            top:-85px;
            max-height:180px;
            height:100%;
            border:5px solid #e1e1e1;
            background:white;
            width:100%;
            &:before {
                position: absolute;
                content: '';
                right: 10px;
                bottom: 12px;
                height: 31px;
                width: 40px;
                background: url(../images/loop-form.png) no-repeat center;
            }
            @media screen and (max-width:991px) {
                max-height:260px;
                position:relative;
                top:0;
                padding-top:30px;
            }
            @media screen and (max-width:767px) {
                height:350px;
                padding-top:0px;
                max-height:260px;
                height:100%;
            }
            .choose-card {
                display:flex;
                width:100%;
                position:absolute;
                top:-5px;
                left:-5px;
                @media screen and (max-width:991px) {
                    background:white;
                    left:0;
                }
                @media screen and (max-width:767px) {
                    flex-direction: column;
                    top:0;
                    position:relative;
                }
                .box {
                    max-width:290px;
                    width:100%;
                    height:55px;
                    display:flex;
                    border:1px solid #e1e1e1;
                    background:white;
                    justify-content: flex-start;
                    align-items: center;
                    border-left:0px;
                    cursor:pointer;
                    transition:0.35s;
                    &:last-child {
                        &.active {
                            border-right: 0;
                        }
                    }
                    @media screen and (max-width:767px) {
                        max-width:900px;
                        width:100%;
                    }
                    &:first-child {
                        border-left:5px solid #e1e1e1;
                        @media screen and (max-width:991px) {
                            border-left:0px;
                        }
                    }

                    &:nth-of-type(1) {
                        .img-icon {
                            background:url('../images/sell-icon.png')
                        }
                        &.active {
                            .img-icon {
                                background:url('../images/sell-icon-active.png')
                            }
                        }
                    }
                    &:nth-of-type(2) {
                        .img-icon {
                            background:url('../images/rent-icon.png')
                        }
                        &.active {
                            .img-icon {
                                background:url('../images/rent-icon-active.png')
                            }
                        }
                    }
                    &:nth-of-type(3) {
                        .img-icon {
                            background:url('../images/local-icon.png')
                        }
                        &.active {
                            .img-icon {
                                background:url('../images/local-icon-active.png')
                            }
                        }
                    }
                    .img-icon {
                        margin-left:20px;
                        margin-right:20px;
                        background-repeat:no-repeat !important;
                        background-position:center;
                        width:26px;
                        height:25px;

                    }
                    .title {
                        color:$color-dark;
                        font-weight:500;
                        font-size:16px;
                    }
                    &.active {
                        border-top:5px solid $color-red;
                        border-bottom:0px;
                        height:51px;
                        .title {
                            color:$color-red;
                        }
                    }
                }
            }
            .choose-card-section {
                width:100%;
                padding-left:15px;
                padding-right:15px;
                padding-top:80px;
                background:white;
                box-sizing: border-box;
                transition:2s;
                animation-duration: 0.9s;
                animation-delay: -0.75s;
                .forms {
                    position:relative;
                    z-index:-999;
                    display:none;
                    &:first-child {
                        display:flex;
                        opacity:1;
                        min-height:71px;
                        height:100%;
                        z-index:1;
                    }
                    flex-wrap:wrap;
                    @media screen and (max-width:991px) {
                        justify-content: center;
                    } 
                    .input-box {
                        margin-right:10px;
                        &:first-child {
                            max-width:350px;
                            //width:100%;
                            @media screen and (max-width:1199px) {
                                max-width:185px;
                            }
                            @media screen and (max-width:991px) {
                                max-width:350px;
                            }
                        }
                        @media screen and (max-width:991px) {
                            margin-bottom:20px;
                        }
                        &:last-child {
                            margin-right:0px;
                        }
                        .text {
                            color:$color-dark;
                            font-size:14px;
                            font-weight:500;
                            text-transform: uppercase;
                            padding-bottom:10px;
                        }
                        .select-style {
                            border:1px solid #e1e1e1;;
                            height:38px;
                            display:flex;
                            align-items: center;
                            margin-right:10px;
                            width:100%;
                            select {
                                padding-left:15px;
                                padding-right:15px;
                                font-weight:bold;
                                font-size:14px;
                                color:$color-dark;
                                border:0;
                                background:none;
                                position:relative;
                                z-index:1;
                                max-width:350px;
                                width:100%;
								height:100%;
								outline:none;
                            }
                        }
                        .from-to {
                            display:flex;
                            input {
                                border:1px solid #e1e1e1;;
                                height:40px;
                                padding-left:15px;
                                padding-right:15px;
                                font-weight:bold;
                                font-size:14px;
                                color:$color-dark;
                                width:90px;
                                box-sizing: border-box;
                                &:first-child {
                                    margin-right:10px;
                                }

                            }
                        }
                    }
                    button {
                        background:$color-dark;
                        font-size:14px;
                        color:white;
                        padding-left:15px;
                        padding-right:15px;
                        height:40px;
                        border:0px;
                        margin-top:30px;
                        cursor:pointer;
                    }
                }
            }
        }
        .about-us {
            width:100%;
            display:flex;
            justify-content: space-between;
            padding-top:200px;
            @media screen and (max-width:1199px) {
                justify-content: center;
                flex-wrap:wrap;
            }
            @media screen and (max-width:991px) {
                padding-top:50px;
            }
            @media screen and (max-width:767px) {
                padding-top:310px;
            }
            .left {
                width:50%;
                @media screen and (max-width:1199px) {
                    width:100%;
                }
                .title {
					color: white;
					font-size: 36px;
					font-weight: 300;
					display: -webkit-box;
					display: -ms-flexbox;
					display: flex;
					flex-direction: column;
					border-left: 5px solid #af1e23;
					padding-left: 10px;
					h1{
						font-size: 36px;
						font-weight: 300;
						margin:0;
					}
                    span {
                        font-size: 17px;
                    }
                }
                .text {
                    color:white;
                    font-weight:300;
                    padding-top:40px;
                    padding-left:15px;
                    max-width:460px;
                    width:100%;
                    font-size:14px;
                    line-height:1.5;
                    @media screen and (max-width:1199px) {
                        max-width:600px;
                        width:100%;
                    }
                }
                .btn-read-more {
                    margin-top:40px;
                    height:40px;
                    display:flex;
                    padding-left:25px;
                    padding-right:25px;
                    display:flex;
                    justify-content: center;
                    align-items:center;
                    width:fit-content;
                    text-decoration: none;
                    font-size:14px;
                    color:white;
                    border:1px solid #516b76;
                }
            }
            .right {
                width:50%;
                display:flex;
                flex-wrap:wrap;
                @media screen and (max-width:1199px) {
                    width:100%;
                    padding-top:100px;
                }
                @media screen and (max-width:991px) {
                    justify-content: center;
                }
                .box {
                    max-width:183px;
                    width:100%;
                    height:183px;
                    border:1px solid #516b76;
                    display:flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    @media screen and (max-width:1199px) {
                        max-width:153px;
                        height:153px;
                    }
                    @media screen and (max-width:991px) {
                        max-width:192px;
                        height:192px;
                    }
                    &:nth-of-type(1) {
                        border-left:0px;
                        border-bottom:0px;
                        @media screen and (max-width:1199px) {
                            border-bottom:1px solid #516b76;
                        }
                        @media screen and (max-width:991px) {
                            border-bottom:0px;
                        }
                    }
                    &:nth-of-type(2) {
                        border-left:0px;
                        border-bottom:0px;
                        @media screen and (max-width:1199px) {
                            border-bottom:1px solid #516b76;
                        }
                        @media screen and (max-width:991px) {
                            border-bottom:0px;
                        }
                    }
                    &:nth-of-type(3) {
                        border-left:0px;
                        border-bottom:0px;
                        @media screen and (max-width:1199px) {
                            border-bottom:1px solid #516b76;
                        }
                        @media screen and (max-width:991px) {
                            border-bottom:0px;
                        }
                        @media screen and (max-width:767px) {
                            border-left:1px solid #516b76;
                        }
                    }
                    &:nth-of-type(4){
                        @media screen and (max-width:767px) {
                            border-bottom:0px;
                        }
                    }
                    &:nth-of-type(5) {
                        border-left:0px;
                        @media screen and (max-width:767px) {
                            border-left:1px solid #516b76;
                        }
                    }
                    &:nth-of-type(6) {
                        border-left:0px;
                    }
                    .img {
                        max-width:190px;
                        width:100%;
                        height:auto;
                        display:block;
                    }
                    div {
                        width:60px;
                        height:60px;
                        display:flex;
                        align-items:center;
                        justify-content: center;
                    }
                    .text {
                        width:100%;
                        text-align:center;
                        color:white;
                        font-size:14px;
                        font-weight:300;
                        margin-top:20px;
                        @media screen and (max-width:1199px) {
                            margin-top:10px;
                            font-size:12px;
                        }
                    }
                }
            }
        }
        .our-portfolio-list {
            display:flex;
            justify-content: space-between;
            margin-top:120px;
            @media screen and (max-width:991px) {
                justify-content: center;
                flex-wrap:wrap;
            }
            .box {
                max-width:215px;
                width:100%;
                display:flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                text-decoration: none;
                @media screen and (max-width:991px) {
                    max-width:200px;
                    margin-bottom:50px;
                }
                .title {
                    font-size:14px;
                    color:white;
                    font-weight:300;
					margin:0;
                }
                .text {
                    font-size:14px;
                    color:white;
                    font-weight:300;
                }
                .date {
                    color:#1b5168;
                    font-weight:500;
                    font-size:48px;
                    position:relative;
                    &:before {
                        position: absolute;
                        content: '';
                        width: 2px;
                        height: 50px;
                        bottom: -77px;
                        background: #032939;
                        left: 50px;

                    }
                }
                .arrow {
                    height:30px;
                    max-width:170px;
                    width:100%;
                    background:#032939;
                    margin-bottom:40px;
                    margin-top:10px;
                    position:relative;
                    @media screen and (max-width:1199px) {
                        display:none;
                    }
                    &:before {
                        height:23px;
                        width:23px;
                        background:#0e394b;
                        position: absolute;
                        content:'';
                        transform: rotate(45deg);
                        left: -12px;
                        top: 3px;
                    }
                    &:after {
                        height:22px;
                        width:23px;
                        background:#032939;
                        position: absolute;
                        content:'';
                        transform: rotate(45deg);
                        right:-13px;
                        top: 4px;
                    }
                }
                img {
                    border-radius:50%;
                    display:block;
                    width:106px;
                    height:106px;
                    border:1px solid grey;
					object-fit:cover;
                }
            }
        }
        .our-portfolio-list-btn {
            color:white;
            font-size:14px;
            text-align:center;
            font-weight:300;
            text-decoration: none;
            margin-top:30px;
            width:100%;
            display:flex;
            justify-content: center;
            align-items: center;
            img {
                margin-left:10px;
            }
        }
    }
}

.wrapper-fourth {
    background:#032939;
    padding-top:75px;
    padding-bottom:75px;
    width:100%;
    .container {
        .top-box {
            display:flex;
            justify-content:space-between;
            align-items: center;
            @media screen and (max-width:500px) {
                flex-direction: column;
                align-items: flex-start;
            }
            .title {
                color: white;
                font-size: 36px;
                font-weight: 300;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                flex-direction: column;
                border-left: 5px solid #af1e23;
                padding-left: 10px;
				h2{
					font-size: 36px;
					font-weight: 300;
					margin:0;
				}
                span {
                    font-size: 17px;
                }
                @media screen and (max-width:500px) {
                    margin-bottom:30px;
                }
            }
            .btn-more {
                font-size: 14px;
                font-weight: 500;
                color: white;
                text-decoration: none;
                display:flex;
                align-items: center;
                img {
                    margin-left: 10px;
                }
                @media screen and (max-width:500px) {
                    padding-left:15px;
                }
            }
        }
        .news-section {
            border:1px solid #516b76;
            padding-top:50px;
            padding-left:0px;
            padding-bottom:50px;
            box-sizing: border-box;
            margin-top:83px;
            position:relative;
            min-height:375px;
            height:100%;
            @media screen and (max-width:1199px) {
                padding-bottom:30px;
            }
            .left {
                width:50%;
                @media screen and (max-width:1199px) {
                    width:46%;
                    padding-left:10px;
                }
                @media screen and (max-width:991px) {
                    width:100%;
                    padding-left:0px;
                }
                .date {
                    color:#1b5168;
                    font-size:18px;
                    font-weight:500;
                    width:100%;
                    max-width:450px;
                    text-align: center;
                    padding-bottom:40px;
                    position:relative;
                    margin-left:auto;
                    margin-right:auto;
                    &:before {
                        position:absolute;
                        content:'';
                        width:60px;
                        height:3px;
                        background:#1b5168;
                        left:0;
                        right:0;
                        margin-left:auto;
                        margin-right:auto;
                        top:45px;
                    }
                }
                .title {
                    font-size:16px;
                    color:white;
                    font-weight:500;
                    max-width:450px;
                    width:100%;
                    text-align:center;
                    padding-bottom:25px;
                    margin-left:auto;
                    margin-right:auto;
                }
                .text {
                    width:100%;
                    max-width:450px;
                    text-align: center;
                    font-size:14px;
                    font-weight:300;
                    color:white;
                    margin-left:auto;
                    margin-right:auto;
                }
                .btn-more {
                    border:1px solid #1b5168;
                    padding-left:25px;
                    padding-right:25px;
                    height:40px;
                    color:white;
                    width:fit-content;
                    font-size:14px;
                    display:flex;
                    justify-content: center;
                    align-items: center;
                    font-weight:300;
                    margin-left:auto;
                    margin-right:auto;
                    margin-top:30px;
                    text-decoration:none;
                }
            }
            .right {
                width:50%;
                position:absolute;
                top:-22px;
                right:15px;
                @media screen and (max-width:1199px) {
                    top:10px;
                }
                @media screen and (max-width:991px) {
                    display:none;
                }
                img {
					display:block;
                    width:555px;
                    height:424px;
					object-fit:cover;
                }
            }
        }
    }
}

.partners_list{
	display:flex;
	flex-direction:row;
	flex-wrap:wrap;
	.partner{
		display:block;
		margin:5px;
		img{
			max-width:300px;
			max-height:200px;
			object-fit:cover;
		}
	}
}

.partner-list {
    padding-top:80px;
    padding-bottom:80px;
    background:white;
    .container {
        display:flex;
        justify-content: space-between;
        @media screen and (max-width:1199px) {
            flex-direction: column;
            justify-content: flex-start;
        }
        .title {
            color: $color-dark;
            font-size: 36px;
            font-weight: 300;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            flex-direction: column;
            border-left: 5px solid #af1e23;
            padding-left: 10px;
			h2{
				font-size: 36px;
				font-weight: 300;
				margin:0;
			}
            span {
                font-size: 17px;
            }
        }
        .slider-partner {
            max-width:800px;
            width:100%;
            @media screen and (max-width:1199px) {
                margin-top:50px;
                margin-left:auto;
                margin-right:auto;
            }
            .slick-prev {
                width:10px; 
                height:20px;
                background:url('../images/partner-left.png');
                &:before {
                    display:none;
                }
            }
            .slick-next {
                width:10px;
                height:20px;
                background:url('../images/partner-right.png');
                &:before {
                    display:none;
                }               
            }
            .slick-list {
                max-width:800px !important;
                width:100%;
                .section {
                    display:flex !important;
                    justify-content: center;
                    align-items: center;
                    height:100px;
                    max-width:2220px;
                    width:100%;
                    img {
                        width:100%;
                        max-width:170px;
                        height:auto;
                        display:block;
                        padding-left:20px;
                        padding-right:20px;
                    }
                }
            } 
        }
    }
}

#fb-widget{
	display:flex;
	flex-direction:row;
	position:fixed;
	z-index:10000;
	top:15%;
	left:-300px;
	width:350px;
	height:500px;
	transition:left .2s linear;
	&.active{
		left:0px;
	}
	.fb-page{
		flex-grow:2;
	}
	.fb-button{
		width:50px;
		height:120px;
		background:url(../images/fb-widget.png) no-repeat;
		cursor:pointer;
	}
}