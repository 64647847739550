.blog-text {
    margin-top:80px;
    padding-bottom:50px;
    .title {
        color:#0e394b;
        font-size:24px;
        font-weight:500;
        line-height:1.5;
		margin:0;
    }
    .date {
        font-size: 16px;
        color: #0e394b;
        padding-bottom: 40px;
        padding-left: 55px;
        position: relative;
        margin-top:40px;
        &:before {
            position: absolute;
            content: '';
            height: 34px;
            width: 34px;
            background: url(../images/icon-date.png) no-repeat center;
            left: 0;
            top: -6px;
        }
    }
    .text-page {
        color:#0e394b;
        font-size:14px;
    }
}