.question-form {
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  top: 0;
  z-index: 9999;
  transition: 5s;
  display: none;
  align-items: center;
  justify-items: center;
  &.active {
    display: flex;
  }
  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 1000;
    background: white;
    padding-top: 80px;
    padding-bottom: 80px;
    box-shadow: 1px 2px 5px 3px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    .close {
      position: absolute;
      font-size: 30px;
      right: 40px;
      top: 20px;
      font-weight: 500;
      color: #032939;
      cursor: pointer;
    }
    .title {
      font-size: 20px;
      color: black;
      text-align: center;
      text-transform: uppercase;
      padding-bottom: 10px;
    }
    .msg {
      padding-bottom: 20px;
      color: red;
    }
    form {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 100%;
      input {
        max-width: 700px;
        width: 100%;
        height: 45px;
        border: 1px solid #d7d7d7;
        color: black;
        margin-bottom: 10px;
        box-sizing: border-box;
        padding-left: 15px;
        font-size: 18px;
        box-sizing: border-box;
        &::placeholder {
          color: black;
        }
        border-radius: 10px;
        outline: none;
      }
      textarea {
        max-width: 700px;
        width: 100%;
        min-height: 95px;
        border: 1px solid #d7d7d7;
        color: black;
        margin-bottom: 30px;
        box-sizing: border-box;
        padding-left: 15px;
        font-size: 18px;
        padding-top: 15px;
        &::placeholder {
          color: black;
        }
        border-radius: 10px;
        outline: none;
      }
      button {
        max-width: 500px;
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #0e394b;
        border: none;
        color: white;
        font-size: 18px;
        margin-top: 50px;
        cursor: pointer;
      }
    }
  }
}

.vis-main{
  .banner {
    display: none;
    &.active {
      display: block;
    }
  }
  .svg-container {
    position: relative;
    margin: 10px 0;
    #svg {
      width: 100%;
      min-height: 300px;
      height: auto;
      &.load {
        background-image: url('../images/svg_loader.gif');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 150px 150px;
      }
      svg {
        display: block;
        width: 100%;
        height: auto;
        margin: auto;
      }
      &.building.not-found {
        display: none;
      }
      &.floor.not-found {
        background-color: #b1b1b1;
        background-image: url('../images/svg_404.png');
        background-repeat: no-repeat;
        background-position: center;
      }
    }
    .svg-tooltip {
      display: none;
      position: absolute;
      left: 0;
      top: 0;
      text-align: center;
      padding: 20px;
      font-size: 14px;
      color: white;
      background-color: black;
      &:after {
        content: '';
        position: absolute;
        width: 20px;
        height: 20px;
        left: calc(50% - 10px);
        bottom: -10px;
        background-color: black;
        transform: rotateZ(45deg);
      }
      span {
        color: #efb518;
      }
      .svg-click-info {
        margin: 10px 0 0;
      }
    }
  }
  .vis-data {
    &.vis-building {
      .vis-object {
        fill: transparent;
        opacity: 0.8;
      }
      .vis-object:hover {
        fill: #40ab19;
      }
      .vis-sold:hover {
        fill: #af1e23;
      }
      .vis-disabled:hover {
        fill: transparent;
      }
    }
    &.vis-floor {
      .vis-object {
        fill: #c3df81;
      }
      .vis-sold {
        fill: #f6b99a;
      }
      .vis-rent {
        fill: #f1ec94;
      }
      .vis-disabled {
        fill: transparent;
      }
    }

    .vis-object:hover {
      cursor: pointer;
    }
    .vis-disabled:hover {
      cursor: default;
    }
  }
  .vis-labels{
    @media screen and (max-width: 991px){
      .vis-label-rect{
	    width:550px;
  	    height:50px;
	  }
	  .vis-label-longrect{
        width:950px;
        height:50px;
	  }
	  .vis-label-text{
	    font-size:35px;
	  }
    }
  }
}

.bulding-list {
  width: 100%;
  padding-bottom: 50px;
  tr.pointer {
    cursor: pointer;
  }

  .map{
	.title-page{
		margin-bottom:15px;
	}
	.map-content{
		display:flex;
		flex-direction:row;
		margin-top:15px;
		@media screen and (max-width: 991px){
			display:block;
		}
		.left{
			width:100%;
			.google-maps{
				width: 100%;
				height: 400px;
				background: #0e394b;
				color: white;
			}
		}
		.right{
			max-width:25%;
			width:100%;
			padding-left:40px;
			@media screen and (max-width: 991px){
				max-width:100%;
				padding-left:0;
				margin-top:15px;
			}
		}
	}
  }
  .wrapper {
    height: 230px;
    width: 100%;
    background: #032939;
    margin-top: -7px;
    margin-bottom: 50px;
    .container {
      padding-top: 50px;
      .title-page {
        color: white !important;
        font-size: 24px;
        font-weight: 300;
        display: flex;
        flex-direction: column;
        border-left: 3px solid #af1e23;
        padding-left: 10px;
        padding-top: 5px;
        padding-bottom: 5px;
      }
      .search-list-portfolio {
        position: absolute;
        top: 126px;
        max-height: 180px;
        height: 100%;
        border: 5px solid #e1e1e1;
        background: white;
        width: 100%;
        &:before {
          position: absolute;
          content: '';
          right: 10px;
          bottom: 12px;
          height: 31px;
          width: 40px;
          background: url(../images/loop-form.png) no-repeat center;
        }
        @media screen and (max-width: 991px) {
          max-height: 260px;
          position: relative;
          top: 0;
          padding-top: 30px;
        }
        @media screen and (max-width: 767px) {
          height: 350px;
          padding-top: 0px;
          max-height: 260px;
          height: 100%;
        }
        .choose-card {
          display: flex;
          width: 100%;
          position: absolute;
          top: -5px;
          left: -5px;
          @media screen and (max-width: 991px) {
            background: white;
            left: 0;
          }
          @media screen and (max-width: 767px) {
            flex-direction: column;
            top: 0;
            position: relative;
          }
          .box {
            max-width: 290px;
            width: 100%;
            height: 55px;
            display: flex;
            border: 1px solid #e1e1e1;
            background: white;
            justify-content: flex-start;
            align-items: center;
            border-left: 0px;
            cursor: pointer;
            transition: 0.35s;
            &:last-child {
              &.active {
                border-right: 0;
              }
            }
            @media screen and (max-width: 767px) {
              max-width: 900px;
              width: 100%;
            }
            &:first-child {
              border-left: 5px solid #e1e1e1;
              @media screen and (max-width: 991px) {
                border-left: 0px;
              }
            }

            &:nth-of-type(1) {
              .img-icon {
                background: url('../images/sell-icon.png')
              }
              &.active {
                .img-icon {
                  background: url('../images/sell-icon-active.png')
                }
              }
            }
            &:nth-of-type(2) {
              .img-icon {
                background: url('../images/rent-icon.png')
              }
              &.active {
                .img-icon {
                  background: url('../images/rent-icon-active.png')
                }
              }
            }
            &:nth-of-type(3) {
              .img-icon {
                background: url('../images/local-icon.png')
              }
              &.active {
                .img-icon {
                  background: url('../images/local-icon-active.png')
                }
              }
            }
            .img-icon {
              margin-left: 20px;
              margin-right: 20px;
              background-repeat: no-repeat !important;
              background-position: center;
              width: 26px;
              height: 25px;

            }
            .title {
              color: $color-dark;
              font-weight: 500;
              font-size: 16px;
            }
            &.active {
              border-top: 5px solid $color-red;
              border-bottom: 0px;
              height: 51px;
              .title {
                color: $color-red;
              }
            }
          }
        }
        .choose-card-section {
          width: 100%;
          padding-left: 15px;
          padding-right: 15px;
          padding-top: 80px;
          background: white;
          box-sizing: border-box;
          transition: 2s;
          animation-duration: 0.9s;
          animation-delay: -0.75s;
          .forms {
            position: relative;
            z-index: -999;
            display: none;
            &:first-child {
              display: flex;
              opacity: 1;
              min-height: 71px;
              height: 100%;
              z-index: 1;
            }
            flex-wrap: wrap;
            @media screen and (max-width: 991px) {
              justify-content: center;
            }
            .input-box {
              margin-right: 10px;
              &:first-child {
                max-width: 350px;
                //width: 100%;
                @media screen and (max-width: 1199px) {
                  max-width: 185px;
                }
                @media screen and (max-width: 991px) {
                  max-width: 350px;
                }
              }
              @media screen and (max-width: 991px) {
                margin-bottom: 20px;
              }
              &:last-child {
                margin-right: 0px;
              }
              .text {
                color: $color-dark;
                font-size: 14px;
                font-weight: 500;
                text-transform: uppercase;
                padding-bottom: 10px;
              }
              .select-style {
                border: 1px solid #e1e1e1;;
                height: 38px;
                display: flex;
                align-items: center;
                margin-right: 10px;
                width: 100%;
                select {
                  padding-left: 15px;
                  padding-right: 15px;
                  font-weight: bold;
                  font-size: 14px;
                  color: $color-dark;
                  border: 0;
                  background: none;
                  position: relative;
                  z-index: 1;
                  max-width: 350px;
                  width: 100%;
                  height: 100%;
                  outline: none;
                }
              }
              .from-to {
                display: flex;
                input {
                  border: 1px solid #e1e1e1;;
                  height: 40px;
                  padding-left: 15px;
                  padding-right: 15px;
                  font-weight: bold;
                  font-size: 14px;
                  color: $color-dark;
                  width: 90px;
                  box-sizing: border-box;
                  &:first-child {
                    margin-right: 10px;
                  }

                }
              }
            }
            button {
              background: $color-dark;
              font-size: 14px;
              color: white;
              padding-left: 15px;
              padding-right: 15px;
              height: 40px;
              border: 0px;
              margin-top: 30px;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
  .content {
    padding-top: 50px;
    @media screen and (max-width: 991px) {
      padding-top: 280px;
    }
    @media screen and (max-width: 767px) {
      padding-top: 500px;
    }
    @media screen and (max-width: 459px) {
      padding-top: 700px;
    }
    .title-page {
      color: #0e394b !important;
      font-size: 24px;
      font-weight: 300;
      display: flex;
      flex-direction: column;
      border-left: 3px solid #af1e23;
      padding-left: 10px;
      padding-top: 5px;
      padding-bottom: 5px;
    }
    .text-content {
      color: #0e394b;
      font-size: 14px;
      font-weight: 500;
      padding-top: 10px;
    }
  }
  .realisations {
    padding-top:50px;
    overflow-x: auto;
    &.rent {
      //padding-top: 200px;
    }
    .title-page {
      color: #0e394b !important;
      font-size: 24px;
      font-weight: 300;
      display: flex;
      flex-direction: column;
      border-left: 3px solid #af1e23;
      padding-left: 10px;
      padding-top: 5px;
      padding-bottom: 5px;
    }

    .apartments_list {
      &.rent {
        tr {
          cursor: pointer;
        }
      }
      th:hover{
        &:after {
          content: " ";
          background: url('../images/arrows.png') no-repeat center;
          display: inline-block;
          position: absolute;
          width: 25px;
          height: 25px;
        }
      }
      //th.descend:hover {
      //  &:after {
      //    content: " ";
      //    background: url('../images/arrows.png') no-repeat center;
      //    display: inline-block;
      //    position: absolute;
      //    width: 25px;
      //    height: 25px;
      //  }
      //}
      //th.ascend:hover{
      //  &:after {
      //    content: " ";
      //    background: url('../images/arrows.png') no-repeat center;
      //    display: inline-block;
      //    position: absolute;
      //    width: 25px;
      //    height: 25px;
      //  }
      //}
      width: 100%;
      border-collapse: collapse;
      padding-top: 80px;
      padding-bottom: 50px;
      padding-left: 5px;
      padding-right: 5px;
      box-sizing: border-box;
      thead {
        width: 100%;
        tr {
          border-bottom: 1px solid #0e394b;
          th {
            color: #8a8a8a;
            font-size: 14px;
            font-weight: 500;
            padding: 20px;
            background-color: white;
            cursor: pointer;
          }
        }
      }
      tbody {
        border-collapse: collapse;
        tr {
          border-bottom: 1px solid #d7d7d7;
          &:last-child {
            bordeR: 0px solid black;
          }
          td {
            text-align: center;
            font-size: 14px;
            font-weight: 500;
            color: #0e394b;
            padding-top: 10px;
            padding-bottom: 10px;
			h3{
				font-size: 14px;
				font-weight: 500;
				margin:0;
			}
          }
          .free {
            color: #2cab00;
          }
          .rez {
            color: #f38010;
          }
          .sold {
            color: #af1e23;
          }
          .apartment_finished {
            color: #af1e23;
          }
          .apartment_finished.active {
            color: #2cab00;
          }
        }
      }
    }
    .btn {
      padding-left: 20px;
      padding-right: 20px;
      color: white;
      font-size: 16px;
      background: #0e394b;
      display: flex;
      justify-content: center;
      align-items: center;
      width: fit-content;
      margin-left: auto;
      margin-right: auto;
      height: 40px;
      text-decoration: none;
      margin-top: 50px;
    }
  }
  .container {
    .title-page {
      color: #0e394b !important;
      font-size: 24px;
      font-weight: 300;
      display: flex;
      flex-direction: column;
      border-left: 3px solid #af1e23;
      padding-left: 10px;
      padding-top: 5px;
      padding-bottom: 5px;
	  margin:0;
    }
    .gallery-section {
      display: flex;
      flex-wrap: wrap;
      padding-top: 50px;
      a {
        max-width: 360px;
        width: 100%;
        height: auto;
        position: relative;
        transition: 5s;
        margin: 5px;
        &:hover:before {
          max-width: 360px;
          width: 100%;
          height: 250px;
          background: rgba(174, 30, 34, 0.6);
          position: absolute;
          content: '';
        }
        &:hover:after {
          position: absolute;
          content: '';
          width: 26px;
          height: 27px;
          left: 0;
          right: 0;
          margin-left: auto;
          margin-right: auto;
          top: 48%;
          background: url('../images/icon-loop.png') no-repeat center;
        }
        img {
          max-width: 360px;
          width: 100%;
          height: 250px;
          object-fit: cover;
        }
      }
    }
  }
}