#investments-background {
    position:relative;
    &:before {
        position:absolute;
        content:'';
        background:url('../images/investment-background.png');
        width:100%;
        height:100%;
        bottom:0;
        left:0;
        z-index:-1;
    }
}

.investments {
    padding-top:50px;
    padding-bottom:50px;
    background:white;
    .title {
        color: #0e394b;
        font-size: 24px;
        font-weight: 300;
        display: flex;
        flex-direction: column;
        border-left: 5px solid #af1e23;
        padding-left: 10px;
        padding-bottom:10px;
        padding-top:10px;
    }
	.text{
		padding-top:80px;
	}
    .investments-content {
        width:100%;
        padding-top:30px;
        img {
            width:99%;
            margin:auto;
        }
        .investments-title {
            color:#0e394b;
            font-size:24px;
            font-weight:300;
            padding-top:25px;
            padding-bottom:25px;
			margin:0;
        }
        .investments-text {
            color:#0e394b;
            font-size:14px;
            font-weight:500;
            padding-bottom:25px;
        }
        .btn-more {
            display:flex;
            justify-content: center;
            align-items: center;
            padding-left:25px;
            padding-right:25px;
            color:white;
            background:#0e394b;
            height:40px;
            width:fit-content;
            text-decoration: none;
            font-size:14px;
        }
    }
}